// @flow

export default function concatNames(firstName?: ?string, lastName?: ?string): string { // TODO: Return maybe type
    if (!firstName && !lastName) {
        return '';
    }
    if (!lastName) {
        return (firstName: any);
    }
    if (!firstName) {
        return (lastName: any);
    }
    return `${firstName} ${lastName}`;
}
