// @flow
import { withStyles } from '@material-ui/core/styles';
import { I18n } from 'aws-amplify';
import MUIDataTable from 'mui-datatables';
import * as React from 'react';
import RefreshToolbarButton from 'src/components/mui-datatables/RefreshToolbarButton';
import UpdatingContentProgress from 'src/components/UpdatingContentProgress';
import type { CustomerId, EmployeeIdAtCompany, EmployeeNumberAtCompany } from 'src/types/Id';
import formatAsCurrency from 'src/utils/formatAsCurrency';
import createFilteredOnDownload from 'src/utils/mui-datatables/createFilteredOnDownload';

/* eslint-disable no-use-before-define */
type Props = {|
    loading: boolean,
    title?: string,
    customers: Array<{|
        customerId: CustomerId,
        customerName: string,
        mobileNumber: string,
        email: string,
        employeeIdAtCompany?: EmployeeIdAtCompany,
        employeeNumberAtCompany?: EmployeeNumberAtCompany,
        companyCredits: string,
        orders: number,
    |}>,
    onClickRefresh: Function;
    classes: Object,
|};

/* eslint-enable no-use-before-define */

export default withStyles(theme => ({
    table: {
        whiteSpace: 'nowrap',
        '& tr': { cursor: 'pointer' },
        '& tr:hover': { backgroundColor: 'rgba(0, 0, 0, 0.07) !important' },
    },
    toolbar: {
        paddingRight: theme.spacing(3),
    },
}))(class CustomerCreditsPaymentsTable extends React.PureComponent<Props> {

    table: any; // TODO: fix correct type

    render() {
        const { classes } = this.props;
        return (
            <div style={{ position: 'relative' }}>
                <UpdatingContentProgress loading={this.props.loading} />
                <MUIDataTable
                    ref={ref => this.table = ref}
                    className={classes.table}
                    title={this.props.title || I18n.get('Customers')}
                    data={this.props.customers.map(customer => ({
                        customerId: customer.customerId,
                        customerName: customer.customerName,
                        mobileNumber: customer.mobileNumber,
                        email: customer.email,
                        companyCredits: formatAsCurrency(customer.companyCredits),
                        orders: customer.orders,
                        employeeIdAtCompany: customer.employeeIdAtCompany ?? '',
                        employeeNumberAtCompany: customer.employeeNumberAtCompany ?? '',
                    }))}
                    columns={[
                        {
                            name: 'customerId',
                            label: ' ',
                            options: {
                                display: 'excluded',
                                filter: false,
                            },
                        },
                        {
                            name: 'customerName',
                            label: I18n.get('Name'),
                            options: {
                                filter: false,
                                searchable: true,
                            },
                        },
                        {
                            name: 'mobileNumber',
                            label: I18n.get('Mobile Number'),
                            options: {
                                filter: false,
                                searchable: true,
                            },
                        },
                        {
                            name: 'email',
                            label: I18n.get('Email'),
                            options: {
                                filter: false,
                                searchable: true,
                            },
                        },
                        {
                            name: 'employeeIdAtCompany',
                            label: I18n.get('Employee Id'),
                            options: {
                                filter: false,
                                searchable: true,
                            },
                        },
                        {
                            name: 'employeeNumberAtCompany',
                            label: I18n.get('Employee Number'),
                            options: {
                                filter: false,
                                searchable: true,
                            },
                        },
                        {
                            name: 'companyCredits',
                            label: I18n.get('Used Credits'),
                            options: {
                                filter: false,
                                searchable: false,
                            },
                        },
                        {
                            name: 'orders',
                            label: I18n.get('Orders'),
                            options: {
                                filter: false,
                                searchable: false,
                            },
                        },
                    ]}
                    options={{
                        responsive: 'scrollMaxHeight',
                        selectableRows: 'none',
                        // setRowProps: (row) => {
                        //     return {
                        //         style: { backgroundColor: getOrderStatusColor(row[33], row[35]) },
                        //     };
                        // },
                        // onRowClick: (rowData: Array<string>, rowMeta: { dataIndex: number, rowIndex: number }) => {
                        //     history.push({
                        //         pathname: RoutePath.CUSTOMER.replace(`:customerId(${RegExps.uuid})`, rowData[0]),
                        //         search: history.location.search,
                        //     });
                        // },
                        filterType: 'multiselect',
                        rowsPerPage: 100,
                        customToolbar: () => {
                            return (
                                <>
                                    <RefreshToolbarButton onClick={this.props.onClickRefresh} />
                                </>
                            );
                        },
                        onDownload: createFilteredOnDownload(this),
                        // filterList: [[], [], [], [], ['MEXICO']],
                    }}
                />
            </div>
        );
    }

});
