// @flow
import { I18n } from 'aws-amplify';
import { BigNumber } from 'bignumber.js';
import OpeningHours from 'opening_hours';
import RegExps from 'src/utils/RegExps';
import trimString from 'src/utils/transforms/trimString';

export const combine = (...validators: Array<string=>string | void>) => {
    return (value: any) => {
        for (let validator of validators) {
            const error = validator(value);
            if (error) {
                return error;
            }
        }
    };
};

const isRequired = I18n.get('is required');
export const required = (value: any) => {
    if (typeof value === 'string' || value instanceof String) {
        return value && value.trim() ? undefined : isRequired;
    } else {
        return value ? undefined : isRequired;
    }
};

export const number = (value: any) => {
    if (!value || !isNaN(value) || !value.trim()) {
        return undefined;
    }
    return (!isNaN(value.replace(',', '.') - parseFloat(value.replace(',', '.')))) ? undefined : I18n.get('is not a number');
};

export const integer = (value: any) => {
    if (!value || !trimString(value)) {
        return;
    }
    if (BigNumber(value).isInteger()) {
        return;
    }
    return I18n.get('is not an integer');
};

export const positive = (value: any) => {
    if (!value || !trimString(value)) {
        return;
    }
    if (BigNumber(value).isNaN()) {
        return;
    }
    if (BigNumber(value).isPositive()) {
        return;
    }
    return I18n.get('is not a positive number');
};

export const greaterThanZero = (value: any) => {
    if (!value || !trimString(value)) {
        return;
    }
    if (BigNumber(value).isNaN()) {
        return;
    }
    if (BigNumber(value).isGreaterThan(0)) {
        return;
    }
    return I18n.get('is not a positive number');
};

const REG_URL = /^(?:(?:(?:http|https):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})).?)(?::\d{2,5})?(?:[/?#]\S*)?$/i;
export const url = (value: any) => {
    if (!value || !value.trim()) {
        return undefined;
    }
    return (REG_URL.test(value)) ? undefined : I18n.get('is not an url');
};

export const https = (value: any) => {
    if (!value || !value.trim()) {
        return undefined;
    }
    return value.startsWith('https://') ? undefined : I18n.get('is not an url starting with https://');
};

const REG_UUID = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
export const uuid = (value: any) => {
    if (!value || !value.trim()) {
        return undefined;
    }
    return (REG_UUID.test(value.trim())) ? undefined : I18n.get('is not an id');
};

export const uuids = (value: any) => {
    if (!value || !value.trim()) {
        return undefined;
    }
    const uuids = value.split(',');
    for (let uuid of uuids) {
        if (!REG_UUID.test(uuid.trim())) {
            return I18n.get('is not a comma separated list of ids');
        }
    }
    return undefined;
};

export const hours = (value: any) => {
    if (!value || !value.trim()) {
        return undefined;
    }

    try {
        new OpeningHours(value);
    } catch (e) {
        return I18n.get('is not valid hours syntax, see https://wiki.openstreetmap.org/wiki/Key:opening_hours');
    }
    return undefined;
};

const REG_TIME_RANGE = new RegExp(RegExps.timeRange);
export const timeRange = (value: any) => {
    if (!value || !value.trim()) {
        return undefined;
    }

    try {
        new OpeningHours(value);
    } catch (e) {
        return I18n.get('is not a valid time range, must be in format 08:00-17:00');
    }
    if (!REG_TIME_RANGE.test(value.trim())) {
        return I18n.get('is not a valid time range, must be in format 08:00-17:00');
    }
    return undefined;
};
