import { all, takeLatest } from 'redux-saga/effects';

import { AppTypes } from 'src/AppReducer';
import * as AppSaga from 'src/AppSaga';
import api from 'src/config/api';

/* ------------- Connect Action Types To Sagas ------------- */
export default function* root() {
    yield all([
        takeLatest(AppTypes.INIT_APP, AppSaga.initApp, api),
        takeLatest(AppTypes.SIGN_IN, AppSaga.signIn, api),
        takeLatest(AppTypes.REFRESH_APP_CONTEXT, AppSaga.refreshAppContext, api),
        takeLatest(AppTypes.SIGN_OUT, AppSaga.signOut, api),
    ]);
}
