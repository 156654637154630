// @flow
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import { I18n } from 'aws-amplify';
import * as React from 'react';
import { connect } from 'react-redux';
import AppActions from 'src/AppReducer';
import BenefitPlans from 'src/scenes/benefit-plan/BenefitPlans';
import type { CompanyId } from 'src/types/Id';

/* eslint-disable no-use-before-define */
type Props = {|
    companyId: CompanyId,
    setAppTitle: string => void,
    classes: Object,
    match: { params: { companyId: CompanyId } }
|};
/* eslint-enable no-use-before-define */

export default connect(state => ({
    companyId: state.app.companyId,
}), {
    setAppTitle: AppActions.setTitle,
})(withStyles(theme => ({}))(class BenefitPlansPage extends React.Component<Props> {


    componentDidMount() {
        this.props.setAppTitle(`${I18n.get('Benefit Plans')}`);
    }

    render() {
        return (
            <Grid container
                  spacing={5} style={{ paddingBottom: 40 }}>
                <Grid item xs={12}>
                    <BenefitPlans title="" companyId={this.props.companyId} />
                </Grid>
            </Grid>
        );
    }

}));
