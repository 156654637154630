// @flow
import { withStyles } from '@material-ui/core/styles';
import { I18n } from 'aws-amplify';
import MUIDataTable from 'mui-datatables';
import * as React from 'react';
import RefreshToolbarButton from 'src/components/mui-datatables/RefreshToolbarButton';
import UpdatingContentProgress from 'src/components/UpdatingContentProgress';
import type { CreditsPaymentVm } from 'src/scenes/credits-billing/CompanyCreditsBillingDataForBenefitPlan';
import formatAsCurrency from 'src/utils/formatAsCurrency';
import createFilteredOnDownload from 'src/utils/mui-datatables/createFilteredOnDownload';
import formatDateTimeString from 'src/utils/transforms/formatDateTimeString';

/* eslint-disable no-use-before-define */
type Props = {|
    loading: boolean,
    title?: string,
    creditsPayments: Array<CreditsPaymentVm>,
    onClickRefresh: Function;
    classes: Object,
|};

/* eslint-enable no-use-before-define */

export default withStyles(theme => ({
    table: {
        whiteSpace: 'nowrap',
        '& tr': { cursor: 'pointer' },
        '& tr:hover': { backgroundColor: 'rgba(0, 0, 0, 0.07) !important' },
    },
    toolbar: {
        paddingRight: theme.spacing(3),
    },
}))(class CreditsPaymentsTable extends React.PureComponent<Props> {

    table: any; // TODO: fix correct type

    render() {
        const { classes } = this.props;
        return (
            <div style={{ position: 'relative' }}>
                <UpdatingContentProgress loading={this.props.loading} />
                <MUIDataTable
                    ref={ref => this.table = ref}
                    className={classes.table}
                    title={this.props.title || I18n.get('Credits Payments')}
                    data={this.props.creditsPayments.map((creditsPayment) => ({
                        orderId: creditsPayment.orderId,
                        customerId: creditsPayment.customerId,
                        createdAt: formatDateTimeString(creditsPayment.createdAt),
                        companyCredits: formatAsCurrency(creditsPayment.companyCredits),
                        customerName: creditsPayment.customerName,
                        mobileNumber: creditsPayment.mobileNumber,
                        email: creditsPayment.email,
                        employeeIdAtCompany: creditsPayment.employeeIdAtCompany ?? '',
                        employeeNumberAtCompany: creditsPayment.employeeNumberAtCompany ?? '',
                    }))}
                    columns={[
                        {
                            name: 'orderId',
                            label: ' ',
                            options: {
                                display: 'excluded',
                                filter: false,
                            },
                        },
                        {
                            name: 'customerId',
                            label: ' ',
                            options: {
                                display: 'excluded',
                                filter: false,
                            },
                        },
                        {
                            name: 'createdAt',
                            label: I18n.get('Paid At'),
                            options: {
                                filter: false,
                                searchable: false,
                            },
                        },
                        {
                            name: 'companyCredits',
                            label: I18n.get('Used Credits'),
                            options: {
                                filter: false,
                                searchable: false,
                            },
                        },
                        {
                            name: 'customerName',
                            label: I18n.get('Name'),
                            options: {
                                filter: false,
                                searchable: true,
                            },
                        },
                        {
                            name: 'mobileNumber',
                            label: I18n.get('Mobile Number'),
                            options: {
                                filter: false,
                                searchable: true,
                            },
                        },
                        {
                            name: 'email',
                            label: I18n.get('Email'),
                            options: {
                                filter: false,
                                searchable: true,
                            },
                        },
                        {
                            name: 'employeeIdAtCompany',
                            label: I18n.get('Employee Id'),
                            options: {
                                filter: false,
                                searchable: true,
                            },
                        },
                        {
                            name: 'employeeNumberAtCompany',
                            label: I18n.get('Employee Number'),
                            options: {
                                filter: false,
                                searchable: true,
                            },
                        },
                    ]}
                    options={{
                        responsive: 'scrollMaxHeight',
                        selectableRows: 'none',
                        filterType: 'multiselect',
                        rowsPerPage: 100,
                        customToolbar: () => {
                            return (
                                <>
                                    <RefreshToolbarButton onClick={this.props.onClickRefresh} />
                                </>
                            );
                        },
                        onDownload: createFilteredOnDownload(this),
                    }}
                />
            </div>
        );
    }

});
