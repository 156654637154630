// @flow
import type { CurrencyFormatVm } from 'src/types/CurrencyFormatVm';

const CURRENCY_FORMAT_MEXICO = {
    locale: 'es-MX',
    currency: 'MXN',
    fractionDigits: 0,
};

export default function formatAsCurrency(number?: ?string, currencyFormat?: CurrencyFormatVm): string { // TODO: Return maybe type
    if (!number) {
        return '';
    }
    if (!currencyFormat) {
        currencyFormat = CURRENCY_FORMAT_MEXICO;
    }

    const fractionDigits = number.includes('.') ? 2 : currencyFormat.fractionDigits;
    return Intl.NumberFormat(currencyFormat.locale, {
        style: 'currency',
        currency: currencyFormat.currency,
        minimumFractionDigits: fractionDigits,
        maximumFractionDigits: fractionDigits,
    }).format((number: any)); // TODO: Create a test that verifies that this it is still possible to format strings since flow seems to complain
}
