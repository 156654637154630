// @flow
import { withStyles } from '@material-ui/core/styles';
import { I18n } from 'aws-amplify';
import MUIDataTable from 'mui-datatables';
import * as React from 'react';
import CreateToolbarButton from 'src/components/mui-datatables/CreateToolbarButton';
import RefreshToolbarButton from 'src/components/mui-datatables/RefreshToolbarButton';
import UpdatingContentProgress from 'src/components/UpdatingContentProgress';
import api from 'src/config/api';
import history from 'src/config/history';
import RoutePath from 'src/constants/RoutePath';
import CreateBenefitPlanDialog, { CREATE_BENEFIT_PLAN } from 'src/scenes/benefit-plan/CreateBenefitPlanDialog';
import type { BenefitPlanId, CompanyId } from 'src/types/Id';
import alertKnownErrorOrSomethingWentWrong from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import translateDaysString from 'src/utils/day/translateDaysString';
import setUrlQueryParameter from 'src/utils/history/setUrlQueryParameter';
import RegExps from 'src/utils/RegExps';
import formatDateTimeString from 'src/utils/transforms/formatDateTimeString';

/* eslint-disable no-use-before-define */
type Props = {|
    companyId: CompanyId,
    title?: string,
    classes: Object,
|};

type State = {
    loading: boolean,
    ...BenefitPlansVm,
};

type BenefitPlansVm = {|
    benefitPlans: Array<BenefitPlanVm>,
|};

type BenefitPlanVm = {|
    benefitPlanId: BenefitPlanId,
    companyId: CompanyId,
    name: string,
    credits: string,
    meals?: number,
    days: string,
    hours?: string,
    paused?: boolean,
    createdAt: Date,
    modifiedAt: Date,
|};

/* eslint-enable no-use-before-define */

function defaultMeals(benefitPlan?: ?BenefitPlanVm): number {
    if (!benefitPlan) {
        return 0;
    }
    if (!benefitPlan.meals) {
        if (!benefitPlan.days) {
            return 0;
        }
        return benefitPlan.days.split(',').length;
    }
    return benefitPlan.meals;
}

export default withStyles(theme => ({
    table: {
        whiteSpace: 'nowrap',
        '& tr': { cursor: 'pointer' },
        '& tr:hover': { backgroundColor: 'rgba(0, 0, 0, 0.07) !important' },
    },
    toolbar: {
        paddingRight: theme.spacing(3),
    },
}))(class BenefitPlans extends React.Component<Props, State> {

    state = {
        loading: false,
        benefitPlans: [],
    };

    async componentDidMount() {
        await this.load();
    }

    setStateAsync(state: Object) {
        return new Promise((resolve) => {
            this.setState(state, resolve);
        });
    }

    // shouldComponentUpdate(nextProps: Props, nextState: State): boolean {
    //     return !deepEqual(this.props, nextProps) || !deepEqual(this.state, nextState);
    // }

    load = async () => {
        await this.setStateAsync({ loading: true });
        const response = await api.getBenefitPlans({ companyId: this.props.companyId });
        await this.setStateAsync({ loading: false });
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        this.setState({ benefitPlans: response.data.benefitPlans });
    };

    handleClickNew = () => {
        history.push({
            search: setUrlQueryParameter(CREATE_BENEFIT_PLAN, ''),
        });
    };

    render() {
        const { classes } = this.props;
        return (
            <div style={{ position: 'relative' }}>
                <UpdatingContentProgress loading={this.state.loading} />
                <CreateBenefitPlanDialog companyId={this.props.companyId} onBenefitPlanCreated={this.load} />
                <MUIDataTable
                    className={classes.table}
                    title={this.props.title || I18n.get('Benefit Plans')}
                    data={this.state.benefitPlans.map((benefitPlan) => ({
                        benefitPlanId: benefitPlan.benefitPlanId,
                        name: benefitPlan.name,
                        credits: benefitPlan.credits,
                        meals: defaultMeals(benefitPlan),
                        days: translateDaysString(benefitPlan.days),
                        hours: benefitPlan.hours || I18n.get('24/7'),
                        paused: benefitPlan.paused ? I18n.get('Paused') : '',
                        createdAt: formatDateTimeString(benefitPlan.createdAt) || '',
                    }))}
                    columns={[
                        {
                            name: 'benefitPlanId',
                            label: ' ',
                            options: {
                                display: 'excluded',
                                filter: false,
                            },
                        },
                        {
                            name: 'name',
                            label: I18n.get('Name'),
                            options: {
                                filter: false,
                            },
                        },
                        {
                            name: 'credits',
                            label: I18n.get('Credits'),
                            options: {
                                filter: false,
                                searchable: false,
                            },
                        },
                        {
                            name: 'meals',
                            label: I18n.get('Meals'),
                            options: {
                                filter: false,
                                searchable: false,
                            },
                        },
                        {
                            name: 'days',
                            label: I18n.get('Days'),
                            options: {
                                filter: false,
                                searchable: false,
                            },
                        },
                        {
                            name: 'hours',
                            label: I18n.get('Hours'),
                            options: {
                                filter: false,
                                searchable: false,
                            },
                        },
                        {
                            name: 'paused',
                            label: I18n.get('Paused'),
                            options: {
                                searchable: false,
                            },
                        },
                        {
                            name: 'createdAt',
                            label: I18n.get('Created'),
                            options: {
                                filter: false,
                                searchable: false,
                            },
                        },
                    ]}
                    options={{
                        responsive: 'scrollMaxHeight',
                        selectableRows: 'none',
                        // setRowProps: (row) => {
                        //     return {
                        //         style: { backgroundColor: getBenefitPlanstatusColor(row[30], row[32]) },
                        //     };
                        // },
                        onRowClick: (rowData: Array<string>, rowMeta: { dataIndex: number, rowIndex: number }) => {
                            history.push({
                                pathname: RoutePath.BENEFIT_PLAN.replace(`:benefitPlanId(${RegExps.uuid})`, rowData[0]),
                                search: history.location.search,
                            });
                        },
                        filterType: 'multiselect',
                        rowsPerPage: 100,
                        customToolbar: () => {
                            return (
                                <>
                                    <RefreshToolbarButton onClick={this.load} />
                                    <CreateToolbarButton title={I18n.get('Create Benefit Plan')} onClick={this.handleClickNew} />
                                </>
                            );
                        },
                        // filterList: [[], [], [], [], ['MEXICO']],
                    }}
                />
            </div>
        );
    }

});
