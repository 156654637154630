const styles = theme => ({
    container: {
        width: '100%',
        height: '100%',
        zIndex: 1,
        overflow: 'hidden',
    },
    appContainer: {
        position: 'relative',
        display: 'flex',
        width: '100%',
        height: '100%',
    },
    appBar: {
        position: 'absolute',
        zIndex: theme.zIndex.drawer + 1,
    },
    navIconHide: {
        marginRight: 16,
        [theme.breakpoints.up('sm')]: {
            marginRight: 20,
        },
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    drawerHeader: theme.mixins.toolbar,
    drawerPaper: {
        backgroundColor: theme.palette.background.default,
        borderRight: 0,
        width: 290,
        height: '100%',
        overflowY: 'hidden',
        '&:hover': {
            overflowY: 'auto',
        },
        [theme.breakpoints.up('md')]: {
            width: 280,
            position: 'relative',
            marginTop: 64,
            height: 'calc(100% - 64px)',
        },
    },
    content: {
        backgroundColor: theme.palette.grey,
        width: '100%',
        padding: theme.spacing(3),
        [theme.breakpoints.down('xs')]: {
            paddingTop: theme.spacing(1),
            paddingBottom: theme.spacing(2),
            paddingRight: 0,
            paddingLeft: 0,
        },
        height: 'calc(100% - 56px)',
        marginTop: 56,
        overflowX: 'hidden',
        overflowY: 'auto',
        [theme.breakpoints.up('sm')]: {
            height: 'calc(100% - 64px)',
            marginTop: 64,
        },
    },
    flex: {
        flex: 1,
    },
    version: {
        fontSize: '0.8rem',
        position: 'absolute',
        bottom: 0,
        right: 4,
        color: 'rgba(0, 0, 0, 0.5)',
    },
});

export default styles;
