// @flow
import { withStyles } from '@material-ui/core/styles';
import { I18n } from 'aws-amplify';
import MUIDataTable from 'mui-datatables';
import * as React from 'react';
import AddToolbarButton from 'src/components/mui-datatables/AddToolbarButton';
import RefreshToolbarButton from 'src/components/mui-datatables/RefreshToolbarButton';
import UpdatingContentProgress from 'src/components/UpdatingContentProgress';
import api from 'src/config/api';
import history from 'src/config/history';
import RoutePath from 'src/constants/RoutePath';
import AddEmployeeDialog, { ADD_EMPLOYEE } from 'src/scenes/employee/AddEmployeeDialog';
import type { BenefitPlanId, CompanyId, CustomerId, EmployeeId, EmployeeIdAtCompany, EmployeeNumberAtCompany } from 'src/types/Id';
import alertKnownErrorOrSomethingWentWrong from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import setUrlQueryParameter from 'src/utils/history/setUrlQueryParameter';
import RegExps from 'src/utils/RegExps';
import concatNames from 'src/utils/transforms/concatNames';
import formatDateTimeString from 'src/utils/transforms/formatDateTimeString';

/* eslint-disable no-use-before-define */
type Props = {|
    benefitPlanId: BenefitPlanId,
    classes: Object,
    refresh: number,
|};

type State = {
    loading: boolean,
    ...EmployeesVm,
};

type EmployeesVm = {|
    employees: Array<EmployeeVm>,
|};

type EmployeeVm = {|
    employeeId: EmployeeId,
    companyId: CompanyId,
    benefitPlanId: BenefitPlanId,
    customerId?: CustomerId,
    email: string,
    employeeIdAtCompany?: EmployeeIdAtCompany,
    employeeNumberAtCompany?: EmployeeNumberAtCompany,
    firstName: string,
    lastName: string,
    paused?: boolean,
    connectedAt?: Date,
    createdAt: Date,
    modifiedAt: Date,
|};
/* eslint-enable no-use-before-define */

export default withStyles(theme => ({
    table: {
        whiteSpace: 'nowrap',
        '& tr': { cursor: 'pointer' },
        '& tr:hover': { backgroundColor: 'rgba(0, 0, 0, 0.07) !important' },
    },
    toolbar: {
        paddingRight: theme.spacing(3),
    },
}))(class Employees extends React.Component<Props, State> {

    state = {
        loading: false,
        employees: [],
    };

    async componentDidMount() {
        await this.load();
    }

    async componentDidUpdate(prevProps, prevState) {
        if (this.props.refresh !== prevProps.refresh) {
            await this.load();
        }
    }

    setStateAsync(state: Object) {
        return new Promise((resolve) => {
            this.setState(state, resolve);
        });
    }

    load = async () => {
        await this.setStateAsync({ loading: true });
        const response = await api.getEmployees({ benefitPlanId: this.props.benefitPlanId });
        await this.setStateAsync({ loading: false });
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        this.setState({ employees: response.data.employees });
    };

    handleClickNew = () => {
        history.push({
            search: setUrlQueryParameter(ADD_EMPLOYEE, ''),
        });
    };

    render() {
        const { classes } = this.props;
        return (
            <div style={{ position: 'relative' }}>
                <UpdatingContentProgress loading={this.state.loading} />
                <AddEmployeeDialog benefitPlanId={this.props.benefitPlanId} onEmployeeAdded={this.load} />
                <MUIDataTable
                    className={classes.table}
                    title={I18n.get('Employees')}
                    data={this.state.employees.map((employee) => ({
                        employeeId: employee.employeeId,
                        name: concatNames(employee.firstName, employee.lastName),
                        email: employee.email ?? '',
                        employeeIdAtCompany: employee.employeeIdAtCompany ?? '',
                        employeeNumberAtCompany: employee.employeeNumberAtCompany ?? '',
                        paused: employee.paused ? I18n.get('Paused') : '',
                        createdAt: formatDateTimeString(employee.createdAt) ?? '',
                        connectedAt: formatDateTimeString(employee.connectedAt) ?? I18n.get('Not connected'),
                    }))}
                    columns={[
                        {
                            name: 'employeeId',
                            label: ' ',
                            options: {
                                display: 'excluded',
                                filter: false,
                            },
                        },
                        {
                            name: 'name',
                            label: I18n.get('Name'),
                            options: {
                                filter: false,
                            },
                        },
                        {
                            name: 'employeeIdAtCompany',
                            label: I18n.get('Employee Id'),
                            options: {
                                filter: false,
                                searchable: true,
                            },
                        },
                        {
                            name: 'employeeNumberAtCompany',
                            label: I18n.get('Employee Number'),
                            options: {
                                filter: false,
                                searchable: true,
                            },
                        },
                        {
                            name: 'email',
                            label: I18n.get('Email'),
                            options: {
                                filter: false,
                            },
                        },
                        {
                            name: 'paused',
                            label: I18n.get('Paused'),
                            options: {
                                searchable: false,
                            },
                        },
                        {
                            name: 'createdAt',
                            label: I18n.get('Created'),
                            options: {
                                filter: false,
                                searchable: false,
                            },
                        },
                        {
                            name: 'connectedAt',
                            label: I18n.get('Connected'),
                            options: {
                                filter: false,
                                searchable: false,
                            },
                        },
                    ]}
                    options={{
                        responsive: 'scrollMaxHeight',
                        selectableRows: 'none',
                        onRowClick: (rowData: Array<string>, rowMeta: { dataIndex: number, rowIndex: number }) => {
                            history.push({
                                pathname: RoutePath.EMPLOYEE.replace(`:employeeId(${RegExps.uuid})`, rowData[0]),
                                search: history.location.search,
                            });
                        },
                        filterType: 'multiselect',
                        rowsPerPage: 100,
                        customToolbar: () => {
                            return (
                                <>
                                    <RefreshToolbarButton onClick={this.load} />
                                    <AddToolbarButton onClick={this.handleClickNew} />
                                </>
                            );
                        },
                    }}
                />
            </div>
        );
    }

});
