// @flow
import { grey } from '@material-ui/core/colors';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import { withStyles } from '@material-ui/core/styles';
import { I18n } from 'aws-amplify';
import * as React from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import RoutePath from 'src/constants/RoutePath';
import type { BenefitPlanId } from 'src/types/Id';
import RegExps from 'src/utils/RegExps';

// required for react-router-dom < 6.0.0
// see https://github.com/ReactTraining/react-router/issues/6056#issuecomment-435524678
const ForwardRefNavLink = React.forwardRef((props, ref) => <NavLink innerRef={ref} {...props} />);

type Props = {
    companyName: string,
    benefitPlans: Array<{| benefitPlanId: BenefitPlanId, name: string |}>,
    handleClose: Function,
    classes: Object,
    appLoading: boolean,
    location: {
        search: string,
    },
};

export default withRouter(withStyles(theme => ({
    sideBar: {
        paddingLeft: 8,
        '@global': {
            a: {
                borderRadius: 2,
                '@global': {
                    h3: {
                        color: grey[700],
                    },
                },
            },
            'a:hover': {
                backgroundColor: 'rgba(0, 0, 0, .03)',
            },
            'a.active': {
                backgroundColor: 'rgba(0, 0, 0, .05)',
                '@global': {
                    h3: {
                        fontWeight: 'bold',
                        color: grey[900],
                    },
                },
            },
        },
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
}), { withTheme: true })(class AppMenu extends React.Component<Props> {
    render() {
        const { classes } = this.props;
        return (
            <div className={classes.sideBar}>
                <div>
                    <List subheader={<ListSubheader>{I18n.get('Agreement')}</ListSubheader>}>
                        <ListItem button component={ForwardRefNavLink} to={{
                            pathname: RoutePath.COMPANY,
                            search: this.props.location.search,
                        }} onClick={this.props.handleClose}>
                            <ListItemText primary={I18n.get('Company Details')} />
                        </ListItem>
                        <ListItem button component={ForwardRefNavLink} exact to={{
                            pathname: RoutePath.BENEFIT_PLANS,
                            search: this.props.location.search,
                        }} onClick={this.props.handleClose}>
                            <ListItemText primary={I18n.get('Benefit Plans')} />
                        </ListItem>
                        {this.props.benefitPlans.map(benefitPlan => (
                            <ListItem button className={classes.nested} component={ForwardRefNavLink} to={{
                                pathname: RoutePath.BENEFIT_PLAN.replace(`:benefitPlanId(${RegExps.uuid})`, benefitPlan.benefitPlanId),
                                search: this.props.location.search,
                            }} onClick={this.props.handleClose}>
                                <ListItemText primary={benefitPlan.name} />
                            </ListItem>
                        ))}
                    </List>
                    <List subheader={<ListSubheader>{I18n.get('Billing')}</ListSubheader>}>
                        <ListItem button component={ForwardRefNavLink} to={{
                            pathname: RoutePath.CREDITS_BILLING,
                            search: this.props.location.search,
                        }} onClick={this.props.handleClose}>
                            <ListItemText primary={I18n.get('Credits Billing')} />
                        </ListItem>
                        <ListItem button component={ForwardRefNavLink} to={{
                            pathname: RoutePath.PAYROLL_BILLING,
                            search: this.props.location.search,
                        }} onClick={this.props.handleClose}>
                            <ListItemText primary={I18n.get('Payroll Billing')} />
                        </ListItem>
                    </List>
                </div>
            </div>
        );
    }
}));
