import Environment from 'src/config/Environment';
import WebServiceApi from 'src/services/WebServiceApi';

export default class WebServiceApiFactory {

    static create(environment) {

        switch (environment) {
            case Environment.LOCALHOST:
                return WebServiceApi.create('http://localhost:3001/letseatcompany');
            case Environment.DEV:
                return WebServiceApi.create('https://dev.api.letseat.mx/letseatcompany');
            case Environment.TEST:
                return WebServiceApi.create('https://test.api.letseat.mx/letseatcompany');
            case Environment.PROD:
                return WebServiceApi.create('https://api.letseat.mx/letseatcompany');
            default:
                throw new Error(`Invalid argument environment=${environment}`);
        }
    }

}
