import { Auth } from 'aws-amplify';
import { call, put, select } from 'redux-saga/effects';
import AppActions from 'src/AppReducer';
import history from 'src/config/history';
import RoutePath from 'src/constants/RoutePath';
import SentryService from 'src/services/SentryService';

export function* initApp(api, action) {
    try {
        const cognitoUser = yield call([Auth, 'currentAuthenticatedUser']);
        yield put(AppActions.signIn(cognitoUser.username));
    } catch (e) {

    }
}

export function* signIn(api) {
    try {
        const response = yield call(api.appContext);
        if (response.ok) {
            const state = yield select();
            SentryService.setUsername(state.app.username);
            SentryService.logInfoBreadcrumb('Successful signIn');
            if (history.location.pathname === '/') {
                history.push(RoutePath.COMPANIES);
            }
            yield put(AppActions.signInSuccess(response.data));
        } else {
            SentryService.logError('Failed to signIn', { response });
            yield call([Auth, 'signOut']);
            yield put(AppActions.signInFailure(response));
        }
    } catch (e) {
        SentryService.logError('Failed to signIn', { e });
        yield call([Auth, 'signOut']);
        yield put(AppActions.signInFailure(e));
    }
}

export function* refreshAppContext(api) {
    try {
        const response = yield call(api.appContext);
        if (response.ok) {
            yield put(AppActions.refreshAppContextSuccess(response.data));
        }
    } catch (e) {
    }
}

export function* signOut() {
    yield call([Auth, 'signOut']);
    history.push(RoutePath.HOME);
    SentryService.clearUsername();
    yield put(AppActions.signOutSuccess());
}

