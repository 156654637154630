const appLayout = {
    dialog: {
        form: {
            display: "flex",
            flexDirection: "column"
        },
    },
};

export default appLayout;
