// @flow
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import RadioGroup from '@material-ui/core/RadioGroup';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { I18n } from 'aws-amplify';
import * as React from 'react';
import { Field, Form } from 'react-final-form';
import { withRouter } from 'react-router-dom';
import ChangeToolbarButton from 'src/components/mui-datatables/ChangeToolbarButton';
import RefreshToolbarButton from 'src/components/mui-datatables/RefreshToolbarButton';
import Radio from 'src/components/Radio';
import TextField from 'src/components/TextField';
import UpdatingContentProgress from 'src/components/UpdatingContentProgress';
import api from 'src/config/api';
import history from 'src/config/history';
import ChangeCompanyDialog, { CHANGE_COMPANY } from 'src/scenes/company/ChangeCompanyDialog';
import type { CompanyId, ConektaCustomerId, StripeCustomerId, Username } from 'src/types/Id';
import alertKnownErrorOrSomethingWentWrong from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import setUrlQueryParameter from 'src/utils/history/setUrlQueryParameter';

/* eslint-disable no-use-before-define */
type Props = {
    companyId: CompanyId,
    onCompanyChange?: (CompanyVm) => {},
    classes: Object,
};

type State = {
    loading: boolean,
    companyDetails: {|
        name: string,
        address?: string,
        username: string,
        contact: {|
            email?: string,
            phoneNumber?: string,
            firstName?: string,
            lastName?: string,
        |},
        paused: string,
    |},
};

type CompanyVm = {|
    companyId: CompanyId,
    username: Username,
    name: string,
    address?: string,
    contact: CompanyContactVm,
    conektaCustomerId?: ConektaCustomerId,
    stripeCustomerId?: StripeCustomerId,
    creditCards: number,
    paused?: boolean,
|};

type CompanyContactVm = {|
    email?: string,
    phoneNumber?: string,
    firstName?: string,
    lastName?: string,
|};
/* eslint-enable no-use-before-define */

const INITIAL_STATE = {
    loading: false,
    companyDetails: {
        name: '',
        address: '',
        username: '',
        contact: {
            email: '',
            phoneNumber: '',
            firstName: '',
            lastName: '',
        },
        paused: '',
    },
};

export default withRouter(withStyles(theme => ({
    linearProgress: {
        position: 'absolute',
        width: '100%',
        bottom: 0,
    },
    root: {
        paddingLeft: 8,
        paddingRight: 8,
    },
}))(class extends React.PureComponent<Props, State> {

    state = INITIAL_STATE;

    setStateAsync(state: Object) {
        return new Promise((resolve) => {
            this.setState(state, resolve);
        });
    }

    async componentDidMount(): Promise<void> {
        await this.load();
    }

    load = async () => {
        await this.setStateAsync({ loading: true });
        const response = await api.getCompany({ companyId: this.props.companyId });
        await this.setStateAsync({ loading: false });
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        const company: CompanyVm = response.data;

        this.props.onCompanyChange && this.props.onCompanyChange(company);

        this.setState({
            companyDetails: {
                name: company.name,
                address: company.address || '',
                username: company.username,
                contact: {
                    email: company.contact.email || '',
                    phoneNumber: company.contact.phoneNumber || '',
                    firstName: company.contact.firstName || '',
                    lastName: company.contact.lastName || '',
                },
                paused: company.paused ? 'true' : 'false',
            },
        });
    };

    handleClickEdit = () => {
        history.push({
            search: setUrlQueryParameter(CHANGE_COMPANY, this.props.companyId),
        });
    };

    handleCompanyChanged = async () => {
        await this.load();
    };

    render() {
        const { classes } = this.props;
        return (
            <div style={{ position: 'relative' }}>
                <ChangeCompanyDialog onCompanyChanged={this.handleCompanyChanged} />
                <UpdatingContentProgress loading={this.state.loading} />
                <Card classes={{ root: classes.root }}>
                    <CardHeader
                        title={<Typography display="block" gutterBottom variant="h6">{I18n.get('Company Details')}</Typography>}
                        action={
                            <div>
                                <RefreshToolbarButton onClick={this.load} />
                                <ChangeToolbarButton onClick={this.handleClickEdit} />
                            </div>
                        }
                    />
                    <CardContent>
                        {this.renderContent()}
                    </CardContent>
                </Card>
            </div>
        );
    }

    onSubmit = form => {
    };

    renderContent() {
        return (<>
            <Form
                onSubmit={this.onSubmit}
                initialValues={{
                    company: this.state.companyDetails || {},
                }}
                render={({ handleSubmit, submitting, pristine, values }) => (
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                            <Field name="company.name"
                                   component={TextField}
                                   label={I18n.get('Name')}
                                   fullWidth
                                   disabled
                            />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <Field name="company.address"
                                   component={TextField}
                                   label={I18n.get('Address')}
                                   fullWidth
                                   disabled
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Field name="company.username"
                                   component={TextField}
                                   label={I18n.get('Username')}
                                   fullWidth
                                   disabled
                            />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <Field name="company.contact.firstName"
                                   component={TextField}
                                   label={I18n.get('Contact First Name')}
                                   fullWidth
                                   disabled
                            />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <Field name="company.contact.lastName"
                                   component={TextField}
                                   label={I18n.get('Contact Last Name')}
                                   fullWidth
                                   disabled
                            />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <Field name="company.contact.email"
                                   component={TextField}
                                   label={I18n.get('Contact Email')}
                                   fullWidth
                                   disabled
                            />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <Field name="company.contact.phoneNumber"
                                   component={TextField}
                                   label={I18n.get('Contact Phone Number')}
                                   fullWidth
                                   disabled
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <RadioGroup row>
                                <FormControlLabel
                                    label={I18n.get('Active')}
                                    control={
                                        <Field
                                            name="company.paused"
                                            component={Radio}
                                            type="radio"
                                            color="primary"
                                            value="false"
                                            disabled
                                        />
                                    }
                                />
                                <FormControlLabel
                                    label={I18n.get('Paused')}
                                    control={
                                        <Field
                                            name="company.paused"
                                            component={Radio}
                                            type="radio"
                                            color="primary"
                                            value="true"
                                            disabled
                                        />
                                    }
                                />
                            </RadioGroup>
                        </Grid>

                    </Grid>
                )}
            />
        </>);
    }

}));
