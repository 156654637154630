// @flow
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import RadioGroup from '@material-ui/core/RadioGroup';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { I18n } from 'aws-amplify';
import * as React from 'react';
import { Field, Form } from 'react-final-form';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import AppActions from 'src/AppReducer';
import Checkbox from 'src/components/Checkbox';
import Radio from 'src/components/Radio';
import Select from 'src/components/Select';
import Spacing from 'src/components/spacing/Spacing';
import TextField from 'src/components/TextField';
import UpdatingContentProgress from 'src/components/UpdatingContentProgress';
import api from 'src/config/api';
import history from 'src/config/history';
import Day from 'src/constants/Day';
import appColors from 'src/theme/AppColors';
import appLayout from 'src/theme/AppLayout';
import type { BenefitPlanId, CompanyId } from 'src/types/Id';
import alertKnownErrorOrSomethingWentWrong from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import sortDays from 'src/utils/day/sortDays';
import deleteUrlQueryParameter from 'src/utils/history/deleteUrlQueryParameter';
import getUrlQueryParameter from 'src/utils/history/getUrlQueryParameter';
import { combine, greaterThanZero, integer, required, timeRange } from 'src/utils/Validator';

/* eslint-disable no-use-before-define */
type Props = {
    classes: Object,
    location: { pathname: string },
    onBenefitPlanChanged: Function,
    refreshAppContext: Function,
};

type State = {
    open: boolean,
    loading: boolean,
    submitting: boolean,
    benefitPlan: {|
        benefitPlanId: BenefitPlanId,
        name: string,
        credits?: string,
        restrictedToDelivery?: boolean,
        meals?: number,
        days: Array<string>,
        hours?: string,
        paused?: string,
    |},
    errorMessage?: string,
};

type BenefitPlanVm = {|
    benefitPlanId: BenefitPlanId,
    companyId: CompanyId,
    name: string,
    credits: string,
    restrictedToDelivery?: boolean,
    meals?: number,
    days: string,
    hours?: string,
    paused?: boolean,
    createdAt: Date,
    modifiedAt: Date,
|};

type ChangeBenefitPlanRequest = {|
    benefitPlanId: BenefitPlanId,
    name?: ?string,
    credits?: ?string,
    restrictedToDelivery?: boolean,
    meals?: ?number,
    days?: ?string,
    hours?: ?string,
    paused?: ?boolean,
|}
/* eslint-enable no-use-before-define */

export const CHANGE_BENEFIT_PLAN = 'change-benefit-plan';
const INITIAL_STATE = {
    open: false,
    benefitPlan: {
        benefitPlanId: ('': any),
        name: '',
        credits: '',
        days: [],
        paused: 'false',
    },
    loading: false,
    submitting: false,
};

export default connect(state => ({}), {
    refreshAppContext: AppActions.refreshAppContext,
})(withRouter(withStyles(theme => ({
    linearProgress: {
        position: 'absolute',
        width: '100%',
        bottom: 0,
    },
    deleteButton: {
        color: appColors.text.dangerLight,
    },
    leftActions: {
        justifyContent: 'space-between',
    },
}))(class ChangeBenefitPlanDialog extends React.PureComponent<Props, State> {

    state = INITIAL_STATE;

    setStateAsync(state: Object) {
        return new Promise((resolve) => {
            this.setState(state, resolve);
        });
    }

    async componentDidMount(): Promise<void> {
        const benefitPlanId: BenefitPlanId = getUrlQueryParameter(CHANGE_BENEFIT_PLAN, this.props.location);
        if (benefitPlanId) {
            await this.openDialog(benefitPlanId);
        }
    }

    async componentDidUpdate(prevProps: Props) {
        const prevBenefitPlanId: BenefitPlanId = getUrlQueryParameter(CHANGE_BENEFIT_PLAN, prevProps.location);
        const benefitPlanId: BenefitPlanId = getUrlQueryParameter(CHANGE_BENEFIT_PLAN, this.props.location);
        if (benefitPlanId && !prevBenefitPlanId && !this.state.open) {
            await this.openDialog(benefitPlanId);
        } else if (!benefitPlanId && prevBenefitPlanId && this.state.open) {
            this.closeDialog();
        }
    }

    handleClose = () => {
        if (this.state.submitting) {
            return;
        }
        if (history.length <= 2) {
            history.replace({
                path: this.props.location.pathname,
                search: deleteUrlQueryParameter(CHANGE_BENEFIT_PLAN),
            });
            return;
        }
        history.goBack();
    };

    handleExited = () => {
        this.setState(INITIAL_STATE);
    };

    openDialog = async (benefitPlanId: BenefitPlanId) => {
        this.setState({ open: true });
        await this.load(benefitPlanId);
    };

    closeDialog = () => {
        this.setState({ open: false });
    };

    load = async (benefitPlanId: BenefitPlanId) => {
        await this.setStateAsync({ loading: true });
        const response = await api.getBenefitPlan({ benefitPlanId });
        await this.setStateAsync({ loading: false });
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            this.setState({ open: false });
            return;
        }
        const benefitPlan: BenefitPlanVm = response.data;
        this.setState({
            benefitPlan: {
                benefitPlanId: benefitPlan.benefitPlanId,
                name: benefitPlan.name,
                credits: benefitPlan.credits || '',
                restrictedToDelivery: benefitPlan.restrictedToDelivery,
                meals: benefitPlan.meals,
                days: benefitPlan.days ? benefitPlan.days.split(',') : [],
                hours: benefitPlan.hours || '',
                paused: benefitPlan.paused ? 'true' : 'false',
            },
        });
    };

    onSubmit = async form => {
        await this.setStateAsync({ submitting: true });
        const response = await api.changeBenefitPlan(({
            benefitPlanId: form.benefitPlan.benefitPlanId,
            name: form.benefitPlan.name,
            credits: form.benefitPlan.credits,
            restrictedToDelivery: form.benefitPlan.restrictedToDelivery,
            meals: parseInt(form.benefitPlan.meals),
            days: sortDays(form.benefitPlan.days).join(','),
            hours: form.benefitPlan.hours,
            paused: form.benefitPlan.paused === 'true',
        }: ChangeBenefitPlanRequest));
        await this.setStateAsync({ submitting: false });
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        this.handleClose();
        this.props.refreshAppContext();
        this.props.onBenefitPlanChanged();
    };

    handleClickDelete = async () => {
        const remove = window.confirm(I18n.get('Are you sure you want to remove this benefit plan?'));
        if (remove) {
            await this.removeBenefitPlan();
            this.props.refreshAppContext();
        }
    };

    removeBenefitPlan = async () => {
        const benefitPlanId: BenefitPlanId = getUrlQueryParameter(CHANGE_BENEFIT_PLAN, this.props.location);
        await this.setStateAsync({ submitting: true });
        const response = await api.removeBenefitPlan({ benefitPlanId });
        await this.setStateAsync({ submitting: false });
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        this.props.onBenefitPlanChanged(true);
        if (history.length <= 2) {
            history.replace({
                path: this.props.location.pathname,
                search: deleteUrlQueryParameter(CHANGE_BENEFIT_PLAN),
            });
            return;
        }
        history.go(-2);
    };

    render() {
        const { classes } = this.props;
        return (
            <Dialog aria-labelledby="change-benefit-plan-dialog-title"
                    fullWidth
                    scroll="body"
                    open={this.state.open}
                    onClose={this.handleClose}
                    onExited={this.handleExited}>
                <Form
                    onSubmit={this.onSubmit}
                    initialValues={{
                        benefitPlan: this.state.benefitPlan,
                    }}
                    render={({ handleSubmit, submitting, pristine, values }) => (

                        <form onSubmit={handleSubmit} noValidate style={appLayout.dialog.form}>
                            <DialogTitle id="change-benefit-plan-dialog-title">{I18n.get('Change Benefit Plan').toUpperCase()}</DialogTitle>
                            <DialogContent>
                                {this.renderDialogContent((values: any))}
                            </DialogContent>
                            <DialogActions className={classes.leftActions}>
                                <div>
                                    <Button onClick={this.handleClickDelete}
                                            disabled={this.state.submitting}
                                            tabIndex="-1"
                                            className={classes.deleteButton}>
                                        {I18n.get('Remove Benefit Plan').toUpperCase()}
                                    </Button>
                                </div>
                                <div>
                                    <Button onClick={this.handleClose}
                                            disabled={this.state.submitting}>
                                        {I18n.get('Cancel').toUpperCase()}
                                    </Button>
                                    <Button color="primary"
                                            type="submit"
                                            disabled={this.state.submitting}>
                                        {this.state.submitting ? I18n.get('Changing').toUpperCase() : I18n.get('Change').toUpperCase()}
                                    </Button>
                                </div>
                            </DialogActions>
                            <UpdatingContentProgress loading={this.state.loading || this.state.submitting} top />
                        </form>
                    )}
                />
            </Dialog>
        );
    }

    renderDialogContent(values: { benefitPlan: { days: Array<string> } }) {
        return (<>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Field name="benefitPlan.name"
                           component={TextField}
                           label={I18n.get('Name')}
                           fullWidth
                           disabled={this.state.submitting}
                           required
                           validate={required}
                    />
                </Grid>

                <Grid item xs={12}>
                    <Field name="benefitPlan.credits"
                           component={TextField}
                           label={I18n.get('Credits')}
                           helperText={`${I18n.get('Amount of credits that the employee can order with')}`}
                           fullWidth
                           disabled={this.state.submitting}
                           required
                           validate={required}
                    />
                </Grid>

                <Grid item xs={12}>
                    <FormControlLabel
                        label={I18n.get('Restrict Credits to Delivery')}
                        control={
                            <Field
                                name="benefitPlan.restrictedToDelivery"
                                component={Checkbox}
                                type="checkbox"
                                color="primary"
                            />
                        }
                    />
                </Grid>

                <Grid item xs={12}>
                    <Field name="benefitPlan.meals"
                           component={TextField}
                           label={I18n.get('Meals')}
                           helperText={`${I18n.get('Max number of meals per week')}`}
                           placeholder={`${values.benefitPlan.days.length}`}
                           InputLabelProps={{ shrink: true }}
                           fullWidth
                           disabled={this.state.submitting}
                           validate={combine(integer, greaterThanZero)}
                    />
                </Grid>

                <Grid item xs={12} sm={10}>
                    <Field fullWidth
                           name="benefitPlan.days"
                           label={I18n.get('Days')}
                           helperText={`${I18n.get('Days of week when the employee can order')}`}
                           component={Select}
                           formControlProps={{ fullWidth: true }}
                           selectMultiple
                           required
                           validate={required}
                    >
                        <MenuItem value={Day.MONDAY}>{I18n.get('Monday')}</MenuItem>
                        <MenuItem value={Day.TUESDAY}>{I18n.get('Tuesday')}</MenuItem>
                        <MenuItem value={Day.WEDNESDAY}>{I18n.get('Wednesday')}</MenuItem>
                        <MenuItem value={Day.THURSDAY}>{I18n.get('Thursday')}</MenuItem>
                        <MenuItem value={Day.FRIDAY}>{I18n.get('Friday')}</MenuItem>
                        <MenuItem value={Day.SATURDAY}>{I18n.get('Saturday')}</MenuItem>
                        <MenuItem value={Day.SUNDAY}>{I18n.get('Sunday')}</MenuItem>
                    </Field>
                </Grid>

                <Grid item xs={12}>
                    <Field name="benefitPlan.hours"
                           component={TextField}
                           label={I18n.get('Hours')}
                           helperText={`${I18n.get('Time of day when the employee can order, e.g. 11:00-13.00')}`}
                           placeholder={I18n.get('24/7')}
                           InputLabelProps={{ shrink: true }}
                           fullWidth
                           disabled={this.state.submitting}
                           validate={timeRange}
                    />
                </Grid>

                <Grid item xs={12}>
                    <RadioGroup row>
                        <FormControlLabel
                            label={I18n.get('Active')}
                            control={
                                <Field
                                    name="benefitPlan.paused"
                                    component={Radio}
                                    type="radio"
                                    color="primary"
                                    value="false"
                                    disabled={this.state.submitting}
                                />
                            }
                        />
                        <FormControlLabel
                            label={I18n.get('Paused')}
                            control={
                                <Field
                                    name="benefitPlan.paused"
                                    component={Radio}
                                    type="radio"
                                    color="primary"
                                    value="true"
                                    disabled={this.state.submitting}
                                />
                            }
                        />
                    </RadioGroup>
                </Grid>

            </Grid>

            <Grid container justify="space-around">
                <Grid item>
                    <Spacing units={8} />
                    {this.state.errorMessage &&
                    <Typography display="block" color="error">{this.state.errorMessage}</Typography>
                    }
                </Grid>
            </Grid>
        </>);
    }

})));
