 // @flow
import { withStyles } from '@material-ui/core/styles';
import { I18n } from 'aws-amplify';
import MUIDataTable from 'mui-datatables';
import * as React from 'react';
import RefreshToolbarButton from 'src/components/mui-datatables/RefreshToolbarButton';
import UpdatingContentProgress from 'src/components/UpdatingContentProgress';
import type { PayrollPaymentVm } from 'src/scenes/payroll-billing/PayrollBillingData';
import formatAsCurrency from 'src/utils/formatAsCurrency';
import createFilteredOnDownload from 'src/utils/mui-datatables/createFilteredOnDownload';
import formatDateTimeString from 'src/utils/transforms/formatDateTimeString';

/* eslint-disable no-use-before-define */
type Props = {|
    loading: boolean,
    title?: string,
    payrollPayments: Array<PayrollPaymentVm>,
    onClickRefresh: Function;
    classes: Object,
|};

/* eslint-enable no-use-before-define */

export default withStyles(theme => ({
    table: {
        whiteSpace: 'nowrap',
        '& tr': { cursor: 'pointer' },
        '& tr:hover': { backgroundColor: 'rgba(0, 0, 0, 0.07) !important' },
    },
    toolbar: {
        paddingRight: theme.spacing(3),
    },
}))(class PayrollPaymentsTable extends React.PureComponent<Props> {

    table: any; // TODO: fix correct type

    render() {
        const { classes } = this.props;
        return (
            <div style={{ position: 'relative' }}>
                <UpdatingContentProgress loading={this.props.loading} />
                <MUIDataTable
                    ref={ref => this.table = ref}
                    className={classes.table}
                    title={this.props.title || I18n.get('All Payments')}
                    data={this.props.payrollPayments.map((payrollPayment) => ({
                        orderId: payrollPayment.orderId,
                        customerId: payrollPayment.customerId,
                        createdAt: formatDateTimeString(payrollPayment.createdAt),
                        paidWithPayroll: formatAsCurrency(payrollPayment.paidWithPayroll),
                        customerName: payrollPayment.customerName,
                        mobileNumber: payrollPayment.mobileNumber,
                        email: payrollPayment.email,
                        employeeIdAtCompany: payrollPayment.employeeIdAtCompany ?? '',
                        employeeNumberAtCompany: payrollPayment.employeeNumberAtCompany ?? '',
                    }))}
                    columns={[
                        {
                            name: 'orderId',
                            label: ' ',
                            options: {
                                display: 'excluded',
                                filter: false,
                            },
                        },
                        {
                            name: 'customerId',
                            label: ' ',
                            options: {
                                display: 'excluded',
                                filter: false,
                            },
                        },
                        {
                            name: 'createdAt',
                            label: I18n.get('Paid At'),
                            options: {
                                filter: false,
                                searchable: false,
                            },
                        },
                        {
                            name: 'paidWithPayroll',
                            label: I18n.get('Paid Amount'),
                            options: {
                                filter: false,
                                searchable: false,
                            },
                        },
                        {
                            name: 'customerName',
                            label: I18n.get('Name'),
                            options: {
                                filter: false,
                                searchable: true,
                            },
                        },
                        {
                            name: 'mobileNumber',
                            label: I18n.get('Mobile Number'),
                            options: {
                                filter: false,
                                searchable: true,
                            },
                        },
                        {
                            name: 'email',
                            label: I18n.get('Email'),
                            options: {
                                filter: false,
                                searchable: true,
                            },
                        },
                        {
                            name: 'employeeIdAtCompany',
                            label: I18n.get('Employee Id'),
                            options: {
                                filter: false,
                                searchable: true,
                            },
                        },
                        {
                            name: 'employeeNumberAtCompany',
                            label: I18n.get('Employee Number'),
                            options: {
                                filter: false,
                                searchable: true,
                            },
                        },
                    ]}
                    options={{
                        responsive: 'scrollMaxHeight',
                        selectableRows: 'none',
                        // setRowProps: (row) => {
                        //     return {
                        //         style: { backgroundColor: getOrderStatusColor(row[33], row[35]) },
                        //     };
                        // },
                        // onRowClick: (rowData: Array<string>, rowMeta: { dataIndex: number, rowIndex: number }) => {
                        //     history.push({
                        //         pathname: RoutePath.CUSTOMER.replace(`:customerId(${RegExps.uuid})`, rowData[0]),
                        //         search: history.location.search,
                        //     });
                        // },
                        filterType: 'multiselect',
                        rowsPerPage: 100,
                        customToolbar: () => {
                            return (
                                <>
                                    <RefreshToolbarButton onClick={this.props.onClickRefresh} />
                                </>
                            );
                        },
                        onDownload: createFilteredOnDownload(this),
                        // filterList: [[], [], [], [], ['MEXICO']],
                    }}
                />
            </div>
        );
    }

});
