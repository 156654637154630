// @flow
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import AddIcon from '@material-ui/icons/Add';
import { I18n } from 'aws-amplify';
import React from 'react';

type Props = {
    tooltip?: string,
    onClick: Function,
};

export default function CreateToolbarButton({ tooltip, onClick }: Props) {
    return (
        <Tooltip title={tooltip || I18n.get('Create')}>
            <IconButton onClick={onClick}>
                <AddIcon />
            </IconButton>
        </Tooltip>
    );
}
