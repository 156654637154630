// @flow
import MomentUtils from '@date-io/moment';
import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import * as React from 'react';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import App from 'src/App';
import AwsConfigurer from 'src/config/AwsConfigurer';
import configureStore from 'src/config/configureStore';
import history from 'src/config/history';
import SentryService from 'src/services/SentryService';
import AppTheme from 'src/theme/AppTheme';
import 'typeface-roboto';
// import DevTools from './config/devtools';
import './index.css';

SentryService.install();
AwsConfigurer.configure();

const store = configureStore();

type Props = {};

class AppRoot extends React.Component<Props> {
    render() {
        return (
                <Provider store={store}>
                    <MuiThemeProvider theme={AppTheme}>
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                            <Router history={history}>
                                {/*<ScrollToTopOnHistoryPush>*/}
                                <div id="app-container">
                                    <CssBaseline />
                                    {/*{process.env.NODE_ENV !== 'production' &&*/}
                                    {/*<DevTools />*/}
                                    {/*}*/}
                                    <App />
                                </div>
                                {/*</ScrollToTopOnHistoryPush>*/}
                            </Router>
                        </MuiPickersUtilsProvider>
                    </MuiThemeProvider>
                </Provider>
        );
    }
}

export default AppRoot;
