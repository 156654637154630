import { I18n } from 'aws-amplify';
import { createActions, createReducer } from 'reduxsauce';

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
    initApp: [],
    signIn: ['username', 'retrying'],
    signInSuccess: ['payload'],
    signInFailure: ['error'],
    refreshAppContext: [],
    refreshAppContextSuccess: ['payload'],
    signOut: [],
    signOutSuccess: [],
    showProgressBar: ['visible', 'variant'],
    showSnackbar: ['config'],
    hideSnackbar: [],
    setTitle: ['title'],
}, { prefix: 'AppReducer/' });

export const AppTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */
export const INITIAL_STATE = {
    loading: false,
    signingIn: false,
    companyId: null,
    username: null,
    companyName: '',
    benefitPlans: [],
    signedIn: false,
    retrying: false,
    signInError: null,
    title: I18n.get('Welcome'),
    progressBar: {
        visible: false,
        variant: 'indeterminate',
    },
    snackbar: {
        isOpen: false,
        message: '',
        actionText: null,
        vertical: 'bottom',
        horizontal: 'center',
        autoHideDuration: 4000,
        onActionClick: null,
        onClose: null,
    },
};

/* ------------- Reducers ------------- */
export const initApp = (state) => {
    return {
        ...state,
        loading: true,
    };
};

export const signIn = (state, { username, retrying }) => {
    return {
        ...INITIAL_STATE,
        signingIn: true,
        retrying: !!retrying,
        username,
    };
};

export const signInSuccess = (state, { payload: { companyId, username, companyName, benefitPlans } }) => {
    return {
        ...state,
        signedIn: true,
        signingIn: false,
        companyId,
        username,
        companyName,
        benefitPlans,
    };
};

export const signInFailure = (state, { error }) => {
    return {
        ...INITIAL_STATE,
        error,
    };
};

export const refreshAppContext = (state) => {
    return state;
};

export const refreshAppContextSuccess = (state, { payload: { companyId, username, companyName, benefitPlans } }) => {
    return {
        ...state,
        companyId,
        username,
        companyName,
        benefitPlans,
    };
};

export const signOut = (state) => {
    return state;
};

export const signOutSuccess = () => {
    return INITIAL_STATE;
};

export const showProgressBar = (state, { visible, variant }) => {
    return {
        ...state,
        progressBar: {
            visible,
            variant: variant || 'indeterminate',
        },
    };
};

export const showSnackbar = (state, { config }) => {
    return {
        ...state,
        snackbar: {
            ...state.snackbar,
            ...config,
            vertical: config.vertical ? config.vertical : 'bottom',
            horizontal: config.horizontal ? config.horizontal : 'center',
            isOpen: true,
        },
    };
};

export const hideSnackbar = (state) => {
    return {
        ...state,
        snackbar: {
            ...INITIAL_STATE.snackbar,
            vertical: state.snackbar.vertical,
            horizontal: state.snackbar.horizontal,
        },
    };
};

export const setTitle = (state, { title }) => {
    return {
        ...state,
        title,
    };
};

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
    [Types.INIT_APP]: initApp,
    [Types.SIGN_IN]: signIn,
    [Types.SIGN_IN_SUCCESS]: signInSuccess,
    [Types.SIGN_IN_FAILURE]: signInFailure,
    [Types.REFRESH_APP_CONTEXT]: refreshAppContext,
    [Types.REFRESH_APP_CONTEXT_SUCCESS]: refreshAppContextSuccess,
    [Types.SIGN_OUT]: signOut,
    [Types.SIGN_OUT_SUCCESS]: signOutSuccess,
    [Types.SHOW_PROGRESS_BAR]: showProgressBar,
    [Types.SHOW_SNACKBAR]: showSnackbar,
    [Types.HIDE_SNACKBAR]: hideSnackbar,
    [Types.SET_TITLE]: setTitle,
});
