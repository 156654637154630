// @flow
import { withTheme } from '@material-ui/core/styles';
import * as React from 'react';

type Props = {
    theme: Object,
    units: number,
    denseGrid?: boolean,
};

class Spacing extends React.PureComponent<Props> {


    static defaultProps = {
        units: 1,
        dense: false,
    };

    render() {
        let height = this.props.theme.spacing(this.props.units) / (this.props.denseGrid ? 2 : 1);
        return (
            <div style={{ height }} />
        );
    }
}

const SpacingWithTheme = withTheme(Spacing);

export default SpacingWithTheme;
