// @flow
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import { I18n } from 'aws-amplify';
import * as React from 'react';
import { connect } from 'react-redux';
import AppActions from 'src/AppReducer';
import BenefitPlanDetails from 'src/scenes/benefit-plan/BenefitPlanDetails';
import UnconnectedCustomers from 'src/scenes/customer/UnconnectedCustomers';
import Employees from 'src/scenes/employee/Employees';
import type { BenefitPlanId } from 'src/types/Id';

/* eslint-disable no-use-before-define */
type Props = {|
    benefitPlanId: BenefitPlanId,
    setAppTitle: string => void,
    classes: Object,
    match: { params: { benefitPlanId: BenefitPlanId } }
|};

type State = {
    refresh?: number,
};
/* eslint-enable no-use-before-define */

export default connect(state => ({}), {
    setAppTitle: AppActions.setTitle,
})(withStyles(theme => ({}))(class BenefitPlanPage extends React.Component<Props, State> {

    state = {};

    handleBenefitPlanChange = (benefitPlan) => {
        this.props.setAppTitle(`${I18n.get('Benefit Plans')} > ${benefitPlan.name}`);
    };

    handleCustomersConnected = () => {
        this.setState({ refresh: this.state.refresh ? this.state.refresh + 1 : 1 });
    };

    render() {
        return (
            <Grid container
                  spacing={5} style={{ paddingBottom: 40 }}>
                <Grid item xs={12}>
                    <BenefitPlanDetails benefitPlanId={this.props.match.params.benefitPlanId} onBenefitPlanChange={this.handleBenefitPlanChange} />
                </Grid>
                <Grid item xs={12}>
                    <Employees benefitPlanId={this.props.match.params.benefitPlanId} refresh={this.state.refresh} />
                </Grid>
                <Grid item xs={12}>
                    <UnconnectedCustomers benefitPlanId={this.props.match.params.benefitPlanId} onCustomersConnected={this.handleCustomersConnected} />
                </Grid>
            </Grid>
        );
    }

}));
