import Amplify from 'aws-amplify';
import AwsConfig from 'src/config/AwsConfig';

export default class AwsConfigurer {

    static configure() {
        Amplify.configure(AwsConfig);
    }

}
