import debugConfig from 'src/config/debugConfig';
import Environment from 'src/config/Environment';

let AwsConfig = {};
if (debugConfig.environment === Environment.PROD || debugConfig.environment === Environment.TEST) {
    AwsConfig = {
        Auth: {
            identityPoolId: 'us-east-1:0dd3d515-a0da-4ea0-8812-803606e99cba',
            region: 'us-east-1',
            userPoolId: 'us-east-1_AaRZCmfoH',
            userPoolWebClientId: '2d54p5ilrkf7biq7uo8b4k69dj',
            mandatorySignIn: true,
        },
    };
} else {
    AwsConfig = {
        Auth: {
            identityPoolId: 'us-east-1:425abb06-d1c7-44d3-a298-28596598465e',
            region: 'us-east-1',
            userPoolId: 'us-east-1_syjiwjydD',
            userPoolWebClientId: '2i7adq28omm845n0dhp2v827g2',
            mandatorySignIn: true,
        },
    };
}

export default AwsConfig;
