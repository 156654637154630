// @flow
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import { DatePicker } from '@material-ui/pickers';
import { I18n } from 'aws-amplify';
import moment from 'moment';
import * as React from 'react';

/* eslint-disable no-use-before-define */
type Props = {|
    filter: {|
        from: Date,
        to: Date,
    |},
    onChangeFilter: {|
        from: Date,
        to: Date,
    |} => void,
    classes: Object,
|};
/* eslint-enable no-use-before-define */

export default withStyles(theme => ({
    table: {
        whiteSpace: 'nowrap',
        '& tr': { cursor: 'pointer' },
        '& tr:hover': { backgroundColor: 'rgba(0, 0, 0, 0.07) !important' },
    },
    toolbar: {
        paddingRight: theme.spacing(3),
    },
}))(class FromToFilter extends React.Component<Props> {

    handleChangeFromDate = date => {
        this.props.onChangeFilter({
            ...this.props.filter,
            from: moment(date).toDate(),
        });
    };

    handleChangeToDate = date => {
        this.props.onChangeFilter({
            ...this.props.filter,
            to: moment(date).toDate(),
        });
    };

    render() {
        return (
            <Card style={{ padding: 24 }}>
                <Grid container
                      direction="row"
                      spacing={3}
                      alignItems="center"
                >
                    <Grid item>
                        <DatePicker
                            variant="inline"

                            label={I18n.get('From')}
                            value={this.props.filter.from}
                            onChange={this.handleChangeFromDate}
                            format="ll"
                            autoOk
                        />
                    </Grid>
                    <Grid item>
                        <DatePicker
                            variant="inline"
                            label={I18n.get('To')}
                            value={this.props.filter.to}
                            onChange={this.handleChangeToDate}
                            format="ll"
                            autoOk
                        />
                    </Grid>
                </Grid>
            </Card>
        );
    }

});
