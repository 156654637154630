// @flow
import Day from 'src/constants/Day';

export default function sortDays(values: Array<$Values<typeof Day>>): Array<$Values<typeof Day>> {
    return [...values].sort((a: $Values<typeof Day>, b: $Values<typeof Day>) => {
        switch (a) {
            case Day.MONDAY:
                return -1;
            case Day.TUESDAY:
                switch (b) {
                    case Day.MONDAY:
                        return 1;
                    default:
                        return -1;
                }
            case Day.WEDNESDAY:
                switch (b) {
                    case Day.MONDAY:
                    case Day.TUESDAY:
                        return 1;
                    default:
                        return -1;
                }
            case Day.THURSDAY:
                switch (b) {
                    case Day.MONDAY:
                    case Day.TUESDAY:
                    case Day.WEDNESDAY:
                        return 1;
                    default:
                        return -1;
                }
            case Day.FRIDAY:
                switch (b) {
                    case Day.MONDAY:
                    case Day.TUESDAY:
                    case Day.WEDNESDAY:
                    case Day.THURSDAY:
                        return 1;
                    default:
                        return -1;
                }
            case Day.SATURDAY:
                switch (b) {
                    case Day.MONDAY:
                    case Day.TUESDAY:
                    case Day.WEDNESDAY:
                    case Day.THURSDAY:
                    case Day.FRIDAY:
                        return 1;
                    default:
                        return -1;
                }
            default:
                return 1;
        }
    });
}
