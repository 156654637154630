// @flow
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { I18n } from 'aws-amplify';
import * as React from 'react';
import { Field, Form } from 'react-final-form';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import AppActions from 'src/AppReducer';
import Checkbox from 'src/components/Checkbox';
import Select from 'src/components/Select';
import Spacing from 'src/components/spacing/Spacing';
import TextField from 'src/components/TextField';
import UpdatingContentProgress from 'src/components/UpdatingContentProgress';
import api from 'src/config/api';
import history from 'src/config/history';
import Day from 'src/constants/Day';
import appLayout from 'src/theme/AppLayout';
import type { BenefitPlanId, CompanyId } from 'src/types/Id';
import alertKnownErrorOrSomethingWentWrong from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import sortDays from 'src/utils/day/sortDays';
import deleteUrlQueryParameter from 'src/utils/history/deleteUrlQueryParameter';
import hasUrlQueryParameter from 'src/utils/history/hasUrlQueryParameter';
import { combine, greaterThanZero, integer, number, required } from 'src/utils/Validator';

/* eslint-disable no-use-before-define */
type Props = {
    companyId: CompanyId,
    classes: Object,
    location: { pathname: string },
    onBenefitPlanCreated: Function,
    refreshAppContext: Function,
};

type State = {
    open: boolean,
    submitting: boolean,
    benefitPlan: {|
        benefitPlanId: BenefitPlanId,
        name: string,
        credits?: string,
        restrictedToDelivery?: boolean,
        meals?: number,
        days: Array<string>,
        paused?: string,
    |},
    errorMessage?: string,
};

type CreateBenefitPlanRequest = {|
    companyId: CompanyId,
    name: string,
    credits: string,
    restrictedToDelivery?: boolean,
    meals?: number,
    days: string,
    paused?: ?boolean,
|};
/* eslint-enable no-use-before-define */

export const CREATE_BENEFIT_PLAN = 'create-benefit-plan';
const INITIAL_STATE = {
    open: false,
    benefitPlan: {
        benefitPlanId: ('': any),
        name: '',
        credits: '',
        restrictedToDelivery: false,
        days: [],
        paused: 'false',
    },
    submitting: false,
};

export default connect(state => ({}), {
    refreshAppContext: AppActions.refreshAppContext,
})(withRouter(withStyles(theme => ({}))(class CreateBenefitPlanDialog extends React.PureComponent<Props, State> {

    state = INITIAL_STATE;

    setStateAsync(state: Object) {
        return new Promise((resolve) => {
            this.setState(state, resolve);
        });
    }

    async componentDidMount(): Promise<void> {
        const createBenefitPlan: boolean = hasUrlQueryParameter(CREATE_BENEFIT_PLAN, this.props.location);
        if (createBenefitPlan) {
            await this.openDialog();
        }
    }

    async componentDidUpdate(prevProps: Props) {
        const prevCreateBenefitPlan: boolean = hasUrlQueryParameter(CREATE_BENEFIT_PLAN, prevProps.location);
        const createBenefitPlan: boolean = hasUrlQueryParameter(CREATE_BENEFIT_PLAN, this.props.location);
        if (createBenefitPlan && !prevCreateBenefitPlan && !this.state.open) {
            await this.openDialog();
        } else if (!createBenefitPlan && prevCreateBenefitPlan && this.state.open) {
            this.closeDialog();
        }
    }

    componentWillUnmount() {

    }

    handleClose = () => {
        if (this.state.submitting) {
            return;
        }
        if (history.length <= 2) {
            history.replace({
                path: this.props.location.pathname,
                search: deleteUrlQueryParameter(CREATE_BENEFIT_PLAN),
            });
            return;
        }
        history.goBack();
    };

    handleExited = () => {
        this.setState(INITIAL_STATE);
    };

    openDialog = () => {
        this.setState({ open: true });
    };

    closeDialog = () => {
        this.setState({ open: false });
    };

    onSubmit = async form => {
        await this.setStateAsync({ submitting: true });
        const request: CreateBenefitPlanRequest = {
            companyId: this.props.companyId,
            name: form.benefitPlan.name,
            credits: form.benefitPlan.credits,
            restrictedToDelivery: form.benefitPlan.restrictedToDelivery,
            meals: parseInt(form.benefitPlan.meals),
            days: sortDays(form.benefitPlan.days).join(','),
        };
        const response = await api.createBenefitPlan(request);
        await this.setStateAsync({ submitting: false });
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        this.props.refreshAppContext();
        this.props.onBenefitPlanCreated();
        this.handleClose();
    };

    render() {
        return (
            <Dialog aria-labelledby="create-benefit-plan-dialog-title"
                    fullWidth
                    scroll="body"
                    open={this.state.open}
                    onClose={this.handleClose}
                    onExited={this.handleExited}>
                <Form
                    onSubmit={this.onSubmit}
                    initialValues={{
                        benefitPlan: this.state.benefitPlan,
                    }}
                    render={({ handleSubmit, submitting, pristine, values }) => {
                        return (

                            <form onSubmit={handleSubmit} noValidate style={appLayout.dialog.form}>
                                <DialogTitle id="create-benefit-plan-dialog-title">{I18n.get('Create BenefitPlan').toUpperCase()}</DialogTitle>
                                <DialogContent>
                                    {this.renderDialogContent(values)}
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={this.handleClose}
                                            disabled={this.state.submitting}>
                                        {I18n.get('Cancel').toUpperCase()}
                                    </Button>
                                    <Button color="primary"
                                            type="submit"
                                            disabled={this.state.submitting}>
                                        {this.state.submitting ? I18n.get('Creating').toUpperCase() : I18n.get('Create').toUpperCase()}
                                    </Button>
                                </DialogActions>
                                <UpdatingContentProgress loading={this.state.submitting} top />
                            </form>
                        );
                    }}
                />
            </Dialog>
        );
    }

    renderDialogContent(values: any) {
        return (<>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Field name="benefitPlan.name"
                           component={TextField}
                           label={I18n.get('Name')}
                           fullWidth
                           disabled={this.state.submitting}
                           required
                           validate={required}
                    />
                </Grid>

                <Grid item xs={12}>
                    <Field name="benefitPlan.credits"
                           component={TextField}
                           label={I18n.get('Credits')}
                           fullWidth
                           disabled={this.state.submitting}
                           required
                           validate={combine(required, number)}
                    />
                </Grid>

                <Grid item xs={12}>
                    <FormControlLabel
                        label={I18n.get('Restrict Credits to Delivery')}
                        control={
                            <Field
                                name="benefitPlan.restrictedToDelivery"
                                component={Checkbox}
                                type="checkbox"
                                color="primary"
                            />
                        }
                    />
                </Grid>

                <Grid item xs={12}>
                    <Field name="benefitPlan.meals"
                           component={TextField}
                           label={I18n.get('Meals')}
                           helperText={`${I18n.get('Max number of meals per week')}`}
                           placeholder={`${values.benefitPlan.days.length}`}
                           InputLabelProps={{ shrink: true }}
                           fullWidth
                           disabled={this.state.submitting}
                           validate={combine(integer, greaterThanZero)}
                    />
                </Grid>

                <Grid item xs={12} sm={10}>
                    <Field fullWidth
                           name="benefitPlan.days"
                           label={I18n.get('Days')}
                           component={Select}
                           formControlProps={{ fullWidth: true }}
                           selectMultiple
                           required
                           validate={required}
                    >
                        <MenuItem value={Day.MONDAY}>{I18n.get('Monday')}</MenuItem>
                        <MenuItem value={Day.TUESDAY}>{I18n.get('Tuesday')}</MenuItem>
                        <MenuItem value={Day.WEDNESDAY}>{I18n.get('Wednesday')}</MenuItem>
                        <MenuItem value={Day.THURSDAY}>{I18n.get('Thursday')}</MenuItem>
                        <MenuItem value={Day.FRIDAY}>{I18n.get('Friday')}</MenuItem>
                        <MenuItem value={Day.SATURDAY}>{I18n.get('Saturday')}</MenuItem>
                        <MenuItem value={Day.SUNDAY}>{I18n.get('Sunday')}</MenuItem>
                    </Field>
                </Grid>

            </Grid>

            <Grid container justify="space-around">
                <Grid item>
                    <Spacing units={8} />
                    {this.state.errorMessage &&
                    <Typography display="block" color="error">{this.state.errorMessage}</Typography>
                    }
                </Grid>
            </Grid>
        </>);
    }

})));
