// @flow
export default function createFilteredOnDownload(that: { table: any }) {
    return (buildHead: Function, buildBody: Function, columns: any) => {
        if (!that.table.state.selectedRows.data.length) {
            return buildHead(columns) + buildBody(that.table.state.displayData);
        }
        const selectedRowsDataIndexes = that.table.state.selectedRows.data.map(d => d.dataIndex);
        const selectedData = that.table.state.displayData.filter(d => selectedRowsDataIndexes.includes(d.dataIndex));
        return buildHead(columns) + buildBody(selectedData);
    };
}
