// @flow
import isString from 'lodash/isString';

export default function trimString(value?: ?string): string { // TODO: Return maybe type
    if (value === undefined || value === null) {
        return (value: any);
    }
    if (!isString(value)) {
        return value;
    }
    return value.trim();
}
