// @flow
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import RadioGroup from '@material-ui/core/RadioGroup';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { I18n } from 'aws-amplify';
import * as React from 'react';
import { Field, Form } from 'react-final-form';
import { withRouter } from 'react-router-dom';
import Radio from 'src/components/Radio';
import Spacing from 'src/components/spacing/Spacing';
import TextField from 'src/components/TextField';
import UpdatingContentProgress from 'src/components/UpdatingContentProgress';
import api from 'src/config/api';
import history from 'src/config/history';
import appColors from 'src/theme/AppColors';
import appLayout from 'src/theme/AppLayout';
import type { BenefitPlanId, CompanyId, CustomerId, EmployeeId, EmployeeIdAtCompany, EmployeeNumberAtCompany } from 'src/types/Id';
import alertKnownErrorOrSomethingWentWrong from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import deleteUrlQueryParameter from 'src/utils/history/deleteUrlQueryParameter';
import getUrlQueryParameter from 'src/utils/history/getUrlQueryParameter';
import { required } from 'src/utils/Validator';

/* eslint-disable no-use-before-define */
type Props = {
    classes: Object,
    location: { pathname: string },
    onEmployeeChanged: Function,
};

type State = {
    open: boolean,
    loading: boolean,
    submitting: boolean,
    employee: {|
        employeeId: EmployeeId,
        benefitPlanId: BenefitPlanId,
        customerId: CustomerId,
        email: string,
        employeeIdAtCompany: string,
        employeeNumberAtCompany: string,
        firstName: string,
        lastName: string,
        paused: string,
    |},
    errorMessage?: string,
};

type EmployeeVm = {|
    employeeId: EmployeeId,
    companyId: CompanyId,
    benefitPlanId: BenefitPlanId,
    customerId: CustomerId,
    email: string,
    employeeIdAtCompany: EmployeeIdAtCompany,
    employeeNumberAtCompany: EmployeeNumberAtCompany,
    firstName: string,
    lastName: string,
    paused?: boolean,
    connectedAt?: Date,
    createdAt: Date,
    modifiedAt: Date,
|};

type ChangeEmployeeRequest = {|
    employeeId: EmployeeId,
    benefitPlanId?: ?BenefitPlanId,
    customerId: BenefitPlanId,
    email?: ?string,
    employeeIdAtCompany?: ?EmployeeIdAtCompany,
    employeeNumberAtCompany?: ?EmployeeNumberAtCompany,
    firstName?: ?string,
    lastName?: ?string,
    paused?: ?boolean,
|}
/* eslint-enable no-use-before-define */

export const CHANGE_EMPLOYEE = 'change-employee';
const INITIAL_STATE = {
    open: false,
    employee: {
        employeeId: ('': any),
        benefitPlanId: ('': any),
        customerId: ('': any),
        email: '',
        employeeIdAtCompany: '',
        employeeNumberAtCompany: '',
        firstName: '',
        lastName: '',
        paused: 'false',
    },
    loading: false,
    submitting: false,
};

export default withRouter(withStyles(theme => ({
    linearProgress: {
        position: 'absolute',
        width: '100%',
        bottom: 0,
    },
    deleteButton: {
        color: appColors.text.dangerLight,
    },
    leftActions: {
        justifyContent: 'space-between',
    },
}))(class ChangeEmployeeDialog extends React.PureComponent<Props, State> {

    state = INITIAL_STATE;

    setStateAsync(state: Object) {
        return new Promise((resolve) => {
            this.setState(state, resolve);
        });
    }

    async componentDidMount(): Promise<void> {
        const employeeId: EmployeeId = getUrlQueryParameter(CHANGE_EMPLOYEE, this.props.location);
        if (employeeId) {
            await this.openDialog(employeeId);
        }
    }

    async componentDidUpdate(prevProps: Props) {
        const prevEmployeeId: EmployeeId = getUrlQueryParameter(CHANGE_EMPLOYEE, prevProps.location);
        const employeeId: EmployeeId = getUrlQueryParameter(CHANGE_EMPLOYEE, this.props.location);
        if (employeeId && !prevEmployeeId && !this.state.open) {
            await this.openDialog(employeeId);
        } else if (!employeeId && prevEmployeeId && this.state.open) {
            this.closeDialog();
        }
    }

    handleClose = () => {
        if (this.state.submitting) {
            return;
        }
        if (history.length <= 2) {
            history.replace({
                path: this.props.location.pathname,
                search: deleteUrlQueryParameter(CHANGE_EMPLOYEE),
            });
            return;
        }
        history.goBack();
    };

    handleExited = () => {
        this.setState(INITIAL_STATE);
    };

    openDialog = async (employeeId: EmployeeId) => {
        this.setState({ open: true });
        await this.load(employeeId);
    };

    closeDialog = () => {
        this.setState({ open: false });
    };

    load = async (employeeId: EmployeeId) => {
        await this.setStateAsync({ loading: true });
        const response = await api.getEmployee({ employeeId });
        await this.setStateAsync({ loading: false });
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            this.setState({ open: false });
            return;
        }
        const employee: EmployeeVm = response.data;
        this.setState({
            employee: {
                employeeId: employee.employeeId,
                benefitPlanId: employee.benefitPlanId,
                customerId: employee.customerId,
                email: employee.email,
                employeeIdAtCompany: employee.employeeIdAtCompany,
                employeeNumberAtCompany: employee.employeeNumberAtCompany,
                firstName: employee.firstName,
                lastName: employee.lastName,
                paused: employee.paused ? 'true' : 'false',
            },
        });
    };

    onSubmit = async form => {
        await this.setStateAsync({ submitting: true });
        const response = await api.changeEmployee(({
            employeeId: form.employee.employeeId,
            benefitPlanId: form.employee.benefitPlanId,
            customerId: form.employee.customerId,
            email: form.employee.email,
            employeeIdAtCompany: form.employee.employeeIdAtCompany ?? null,
            employeeNumberAtCompany: form.employee.employeeNumberAtCompany ?? null,
            firstName: form.employee.firstName,
            lastName: form.employee.lastName,
            paused: form.employee.paused === 'true',
        }: ChangeEmployeeRequest));
        await this.setStateAsync({ submitting: false });
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        this.handleClose();
        this.props.onEmployeeChanged();
    };

    handleClickDelete = async () => {
        const remove = window.confirm(I18n.get('Are you sure you want to remove this employee?'));
        if (remove) {
            await this.removeEmployee();
        }
    };

    removeEmployee = async () => {
        const employeeId: EmployeeId = getUrlQueryParameter(CHANGE_EMPLOYEE, this.props.location);
        await this.setStateAsync({ submitting: true });
        const response = await api.removeEmployee({ employeeId });
        await this.setStateAsync({ submitting: false });
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        this.props.onEmployeeChanged(true);
        if (history.length <= 2) {
            history.replace({
                path: this.props.location.pathname,
                search: deleteUrlQueryParameter(CHANGE_EMPLOYEE),
            });
            return;
        }
        history.go(-2);
    };

    render() {
        const { classes } = this.props;
        return (
            <Dialog aria-labelledby="change-employee-dialog-title"
                    fullWidth
                    scroll="body"
                    open={this.state.open}
                    onClose={this.handleClose}
                    onExited={this.handleExited}>
                <Form
                    onSubmit={this.onSubmit}
                    initialValues={{
                        employee: this.state.employee,
                    }}
                    render={({ handleSubmit, submitting, pristine, values }) => (

                        <form onSubmit={handleSubmit} noValidate style={appLayout.dialog.form}>
                            <DialogTitle id="change-employee-dialog-title">{I18n.get('Change Employee').toUpperCase()}</DialogTitle>
                            <DialogContent>
                                {this.renderDialogContent()}
                            </DialogContent>
                            <DialogActions className={classes.leftActions}>
                                <div>
                                    <Button onClick={this.handleClickDelete}
                                            disabled={this.state.submitting}
                                            tabIndex="-1"
                                            className={classes.deleteButton}>
                                        {I18n.get('Remove Employee').toUpperCase()}
                                    </Button>
                                </div>
                                <div>
                                    <Button onClick={this.handleClose}
                                            disabled={this.state.submitting}>
                                        {I18n.get('Cancel').toUpperCase()}
                                    </Button>
                                    <Button color="primary"
                                            type="submit"
                                            disabled={this.state.submitting}>
                                        {this.state.submitting ? I18n.get('Changing').toUpperCase() : I18n.get('Change').toUpperCase()}
                                    </Button>
                                </div>
                            </DialogActions>
                            <UpdatingContentProgress loading={this.state.loading || this.state.submitting} top />
                        </form>
                    )}
                />
            </Dialog>
        );
    }

    renderDialogContent() {
        return (<>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Field name="employee.firstName"
                           component={TextField}
                           label={I18n.get('First Name')}
                           fullWidth
                           disabled={this.state.submitting}
                           required
                           validate={required}
                           autoComplete='none'
                    />
                </Grid>

                <Grid item xs={12}>
                    <Field name="employee.lastName"
                           component={TextField}
                           label={I18n.get('Last Name')}
                           fullWidth
                           disabled={this.state.submitting}
                           required
                           validate={required}
                           autoComplete='none'
                    />
                </Grid>

                <Grid item xs={12}>
                    <Field name="employee.email"
                           component={TextField}
                           label={I18n.get('Email')}
                           fullWidth
                           disabled={this.state.submitting}
                           required
                           validate={required}
                           autoComplete='none'
                    />
                </Grid>

                <Grid item xs={12}>
                    <Field name="employee.employeeIdAtCompany"
                           component={TextField}
                           label={I18n.get('Employee Id')}
                           fullWidth
                           disabled={this.state.submitting}
                           autoComplete='none'
                    />
                </Grid>

                <Grid item xs={12}>
                    <Field name="employee.employeeNumberAtCompany"
                           component={TextField}
                           label={I18n.get('Employee Number')}
                           fullWidth
                           disabled={this.state.submitting}
                           autoComplete='none'
                    />
                </Grid>

                <Grid item xs={12}>
                    <RadioGroup row>
                        <FormControlLabel
                            label={I18n.get('Active')}
                            control={
                                <Field
                                    name="employee.paused"
                                    component={Radio}
                                    type="radio"
                                    color="primary"
                                    value="false"
                                    disabled={this.state.submitting}
                                />
                            }
                        />
                        <FormControlLabel
                            label={I18n.get('Paused')}
                            control={
                                <Field
                                    name="employee.paused"
                                    component={Radio}
                                    type="radio"
                                    color="primary"
                                    value="true"
                                    disabled={this.state.submitting}
                                />
                            }
                        />
                    </RadioGroup>
                </Grid>

            </Grid>

            <Grid container justify="space-around">
                <Grid item>
                    <Spacing units={8} />
                    {this.state.errorMessage &&
                     <Typography display="block" color="error">{this.state.errorMessage}</Typography>
                    }
                </Grid>
            </Grid>
        </>);
    }

}));
