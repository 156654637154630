// @flow
import { I18n } from 'aws-amplify';

export default function alertKnownErrorOrSomethingWentWrong(response: any): void {
    if (!response) {
        alert(I18n.get('Something went wrong'));
        return;
    }
    if (response.data?.message) {
        alert(response.data.message);
    }
    alert(`${I18n.get('Something went wrong')} (${response.status ?? ''}: ${response.problem ?? ''})`);
}
