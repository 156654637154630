// @flow
import LinearProgress from '@material-ui/core/LinearProgress';
import { withStyles } from '@material-ui/core/styles';
import * as React from 'react';

type Props = {|
    loading?: boolean,
    top?: boolean,
    bottom?: boolean,
    classes: Object,
|};
export default withStyles(theme => ({
    linearProgress: {
        position: 'absolute',
        width: '100%',
    },
}))(class UpdatingContentProgress extends React.PureComponent<Props> {
    render() {
        if (this.props.loading === false) {
            return null;
        }
        const { classes } = this.props;
        return (
            <LinearProgress className={classes.linearProgress} style={{ ...(this.props.top ? { top: 0 } : {}), ...(this.props.bottom ? { bottom: 0 } : {})}} />
        )
    }
});
