// @flow
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import EditIcon from '@material-ui/icons/Edit';
import { I18n } from 'aws-amplify';
import React from 'react';

type Props = {
    tooltip?: string,
    onClick: Function,
};

export default function ChangeToolbarButton({ tooltip, onClick }: Props) {
    return (
        <Tooltip title={tooltip || I18n.get('Change')}>
            <IconButton onClick={onClick}>
                <EditIcon />
            </IconButton>
        </Tooltip>
    );
}
