// @flow
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import { I18n } from 'aws-amplify';
import moment from 'moment';
import * as React from 'react';
import { connect } from 'react-redux';
import AppActions from 'src/AppReducer';
import FromToFilter from 'src/components/FromToFilter';
import PayrollBillingData from 'src/scenes/payroll-billing/PayrollBillingData';
import type { CompanyId } from 'src/types/Id';

/* eslint-disable no-use-before-define */
type Props = {|
    companyId: CompanyId,
    setAppTitle: string => void,
    classes: Object,
|};

type State = {|
    filter: {|
        from: Date,
        to: Date,
    |},
|};
/* eslint-enable no-use-before-define */

export default connect(state => ({
    companyId: state.app.companyId,
}), {
    setAppTitle: AppActions.setTitle,
})(withStyles(theme => ({}))(class CompanyPayrollBillingPage extends React.Component<Props, State> {

    state = {
        filter: {
            from: moment().subtract(1, 'week').startOf('isoWeek').toDate(),
            to: moment().subtract(1, 'week').endOf('isoWeek').toDate(),
        },
    };

    async componentDidMount() {
        this.props.setAppTitle(I18n.get('Payroll Billing'));
    }

    handleChangeFilter = (filter) => {
        this.setState({ filter });
    };

    render() {
        return (
            <Grid container
                  spacing={5}>
                <Grid item xs={12}>
                    <FromToFilter filter={this.state.filter} onChangeFilter={this.handleChangeFilter} />
                </Grid>
                <Grid item xs={12}>
                    <PayrollBillingData filter={this.state.filter} companyId={this.props.companyId} />
                </Grid>
            </Grid>
        );
    }

}));
