import { applyMiddleware, compose, createStore } from 'redux';
import immutableStateInvariantMiddleware from 'redux-immutable-state-invariant';
// import { createLogger } from 'redux-logger';
import createSagaMiddleware from 'redux-saga';
import 'regenerator-runtime/runtime';
import reducers from 'src/reducers';
import rootSaga from 'src/sagas';
// import DevTools from './devtools';

const sagaMiddleware = createSagaMiddleware();

// const isDebuggingInChrome = process.env.NODE_ENV === 'development' && !!window.navigator.userAgent; // eslint-disable-line no-undef

const middlewares = process.env.NODE_ENV === 'development' ?
    [applyMiddleware(
        immutableStateInvariantMiddleware(),
        sagaMiddleware,
    ),
        // DevTools.instrument()
    ] :
    [applyMiddleware(sagaMiddleware)];

const configureStore = (initialState = {}) => {
    const store = createStore(reducers, initialState, compose(...middlewares));
    sagaMiddleware.run(rootSaga);
    return store;
};

export default configureStore;
