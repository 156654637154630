// @flow
import * as React from 'react';
import { connect } from 'react-redux';
import { Route, Switch, withRouter } from 'react-router-dom';
import RoutePath from 'src/constants/RoutePath';
import BenefitPlanPage from 'src/scenes/BenefitPlanPage';
import BenefitPlansPage from 'src/scenes/BenefitPlansPage';
import CompanyPage from 'src/scenes/CompanyPage';
import CompanyCreditsBillingPage from 'src/scenes/CreditsBillingPage';
import EmployeePage from 'src/scenes/EmployeePage';
import CompanyPayrollBillingPage from 'src/scenes/PayrollBillingPage';
import type { CompanyId } from 'src/types/Id';

type Props = {
    companyId: CompanyId,
};

export default withRouter(connect(state => ({
    companyId: state.app.companyId,
}), {})(class AppRoutes extends React.Component<Props> {

    render() {
        if (!this.props.companyId) {
            return null;
        }

        return (<>
            <Route path={RoutePath.COMPANY} component={CompanyPage} />
            <Switch>
                <Route path={RoutePath.BENEFIT_PLANS} exact component={BenefitPlansPage} />
                <Route path={RoutePath.BENEFIT_PLAN} component={BenefitPlanPage} />
            </Switch>
            <Route path={RoutePath.EMPLOYEE} component={EmployeePage} />
            <Route path={RoutePath.CREDITS_BILLING} component={CompanyCreditsBillingPage} />
            <Route path={RoutePath.PAYROLL_BILLING} component={CompanyPayrollBillingPage} />
        </>);
    }
}));
