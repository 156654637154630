// @flow
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import LinearProgress from '@material-ui/core/LinearProgress';
import Snackbar from '@material-ui/core/Snackbar';
import { withStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import MenuIcon from '@material-ui/icons/Menu';
import { Hub, I18n } from 'aws-amplify';
import * as React from 'react';
import { hot } from 'react-hot-loader/root';
import { connect } from 'react-redux';
import { Route, withRouter } from 'react-router-dom';
import SideBar from 'src/AppMenu';
import AppActions from 'src/AppReducer';
import type { SnackbarType } from 'src/AppReducerTypes';
import AppRoutes from 'src/AppRoutes';
import styles from 'src/AppStyles';
import { APP_VERSION } from 'src/config/appVersion';
import debugConfig from 'src/config/debugConfig';
import Environment from 'src/config/Environment';
import RoutePath from 'src/constants/RoutePath';
import { MINUTES } from 'src/constants/TimeUnit';
import Home from 'src/scenes/Home';
import SentryService from 'src/services/SentryService';
import type { BenefitPlanId, Username } from 'src/types/Id';
import './config/configureI18n';
import './config/configureMoment';
import './styles/Hover.css';

type Props = {
    title: string,
    username: Username,
    companyName: string,
    benefitPlans: Array<{| benefitPlanId: BenefitPlanId, name: string |}>,
    signedIn: boolean,
    initApp: Function,
    signIn: Function,
    signOut: Function,
    showSnackbar: Function,
    snackbar: SnackbarType,
    hideSnackbar: Function,
    classes: Object,
    theme: Object,
    appLoading: boolean,
    progressBar: {
        visible: boolean,
        variant: string,
    },
};

type State = {
    showModal: boolean,
    sideBarOpen: boolean,
};

class App extends React.Component<Props, State> {

    static defaultProps = {
        signedIn: false,
        appLoading: false,
    };

    constructor(props: Props) {
        super(props);
        Hub.listen('auth', function (data) {
            // eslint-disable-next-line default-case
            switch (data.payload.event) {
                case 'signIn': {
                    const cognitoUser = data.payload.data;
                    props.signIn(cognitoUser.username);
                    break;
                }
            }
        });
        this.state = {
            showModal: false,
            sideBarOpen: false,
            // anchorEl: null,
        };
    }

    interval: IntervalID;
    serviceWorkerErrorSentToSentry = false;

    toggleSideBar = () => {
        this.setState({ sideBarOpen: !this.state.sideBarOpen });
    };

    closeSideBar = () => {
        this.setState({ sideBarOpen: false });
    };

    handleClickSignOut = () => {
        this.props.signOut();
    };

    handleActionClickSnackbar = () => {
        if (this.props.snackbar.onActionClick) {
            this.props.snackbar.onActionClick();
            return;
        }
        this.props.hideSnackbar();
    };

    handleCloseSnackbar = () => {
        if (this.props.snackbar.onClose) {
            this.props.snackbar.onClose();
            return;
        }
        this.props.hideSnackbar();
    };

    renderRightToolbar = () => {
        if (this.props.signedIn) {
            const content = [];
            content.push(
                <Button key="sign-out-button" color="inherit" onClick={this.handleClickSignOut}>
                    {I18n.get('Sign out')}
                </Button>,
            );
            return (content);
        }
    };

    async componentDidMount() {
        // await this.checkForUpdate();
        this.interval = setInterval(async () => {
            await this.checkForUpdate();
        }, 5 * MINUTES);
        this.props.initApp();
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    async checkForUpdate() {
        if (debugConfig.environment === Environment.LOCALHOST) {
            console.info(`Skipping service worker update check since environment is localhost`);
            return;
        }
        try {
            if ('serviceWorker' in navigator) {
                if (navigator.serviceWorker && navigator.serviceWorker.ready) {
                    const registration = await navigator.serviceWorker.ready;
                    await registration.update();
                }
            } else {
                if (this.serviceWorkerErrorSentToSentry) return;
                this.serviceWorkerErrorSentToSentry = true;
                console.error('Service worker is not supported by the browser');
                SentryService.logInfo('Service worker is not supported by the browser');
                SentryService.logInfoBreadcrumb('Service worker is not supported by the browser');
            }
        } catch (e) {
            if (this.serviceWorkerErrorSentToSentry) return;
            this.serviceWorkerErrorSentToSentry = true;
            console.error('Failed to check if service worker is updated', e);
            SentryService.logInfo('Failed to check if service worker is updated', { e }); // TODO: Change to log breadcrumb after this is verified to be no problem with sentry
            SentryService.logInfoBreadcrumb('Failed to check if service worker is updated', { e });
        }
    }

    render() {
        const { classes, theme } = this.props;

        // TODO: Change to BrowserRouter and add support for refreshing non root urls, e.g. "/orders"
        return (
            <div className={classes.container}>
                <div className={classes.appContainer}>
                    <AppBar className={classes.appBar}>
                        <Toolbar>
                            {this.props.signedIn &&
                            <IconButton
                                color="inherit"
                                aria-label="open drawer"
                                onClick={this.toggleSideBar}
                                className={classes.navIconHide}
                            >
                                <MenuIcon />
                            </IconButton>
                            }
                            <Typography display="block" variant="h6" color="inherit" noWrap className={classes.flex}>
                                {this.props.title}
                            </Typography>
                            {this.renderRightToolbar()}
                        </Toolbar>
                        {this.props.progressBar.visible &&
                        <LinearProgress variant={this.props.progressBar.variant} style={{
                            position: 'absolute',
                            bottom: 0,
                            left: 0,
                            right: 0,
                        }} />
                        }
                    </AppBar>
                    {this.props.signedIn &&
                    <Hidden mdUp>
                        <Drawer
                            variant="temporary"
                            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                            open={this.state.sideBarOpen}
                            classes={{
                                paper: classes.drawerPaper,
                            }}
                            onClose={this.closeSideBar}
                            ModalProps={{
                                keepMounted: true, // Better open performance on mobile.
                            }}
                        >
                            <SideBar
                                handleClose={this.closeSideBar}
                                companyName={this.props.companyName}
                                benefitPlans={this.props.benefitPlans}
                                appLoading={this.props.appLoading}
                            />
                        </Drawer>
                    </Hidden>
                    }
                    {this.props.signedIn &&
                    <Hidden smDown>
                        <Drawer
                            variant="permanent"
                            open
                            classes={{
                                paper: classes.drawerPaper,
                            }}
                        >
                            <SideBar
                                handleClose={this.closeSideBar}
                                companyName={this.props.companyName}
                                benefitPlans={this.props.benefitPlans}
                                appLoading={this.props.appLoading}
                            />
                        </Drawer>
                    </Hidden>
                    }
                    <main className={classes.content}>
                        {this.props.signedIn &&
                        <AppRoutes />
                        }
                        {!this.props.signedIn &&
                        <Route path={RoutePath.HOME} component={Home} />
                        }
                    </main>
                </div>

                <Snackbar
                    anchorOrigin={{ vertical: this.props.snackbar.vertical, horizontal: this.props.snackbar.horizontal }}
                    open={this.props.snackbar.isOpen}
                    message={this.props.snackbar.message}
                    action={this.props.snackbar.actionText ?
                        <Button color="secondary" size="small" onClick={this.handleActionClickSnackbar}>{this.props.snackbar.actionText}</Button>
                        :
                        null
                    }
                    autoHideDuration={this.props.snackbar.autoHideDuration}
                    onClose={this.handleCloseSnackbar}
                />
                <div className={classes.version}>
                    {APP_VERSION}
                </div>
            </div>
        );
    }
}

const AppWithStyles = withStyles(styles, { withTheme: true })(App);

const mapStateToProps = state => {
    return {
        admin: state.app.admin,
        username: state.app.username,
        companyName: state.app.companyName,
        benefitPlans: state.app.benefitPlans,
        restaurant: state.app.restaurant,
        appLoading: state.app.loading,
        signedIn: state.app.signedIn,
        title: state.app.title,
        snackbar: state.app.snackbar,
        progressBar: state.app.progressBar,
    };
};

const mapDispatchToProps = {
    initApp: AppActions.initApp,
    signIn: AppActions.signIn,
    signOut: AppActions.signOut,
    showSnackbar: AppActions.showSnackbar,
    hideSnackbar: AppActions.hideSnackbar,
};

const AppWithStylesConnected = connect(
    mapStateToProps,
    mapDispatchToProps,
)(AppWithStyles);

const AppWithRouter = withRouter(AppWithStylesConnected);

export default process.env.NODE_ENV === 'development' ? hot(AppWithRouter) : AppWithRouter;
