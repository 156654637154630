// @flow
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import { I18n } from 'aws-amplify';
import * as React from 'react';
import { connect } from 'react-redux';
import AppActions from 'src/AppReducer';
import EmployeeDetails from 'src/scenes/employee/EmployeeDetails';
import type { CustomerId, EmployeeId } from 'src/types/Id';
import concatNames from 'src/utils/transforms/concatNames';

/* eslint-disable no-use-before-define */
type Props = {|
    employeeId: EmployeeId,
    setAppTitle: string => void,
    classes: Object,
    match: { params: { employeeId: EmployeeId } }
|};

type State = {
    customerId?: CustomerId,
};
/* eslint-enable no-use-before-define */

export default connect(state => ({}), {
    setAppTitle: AppActions.setTitle,
})(withStyles(theme => ({}))(class EmployeePage extends React.Component<Props, State> {

    state = {};

    handleEmployeeChange = (employee) => {
        this.props.setAppTitle(`${I18n.get('Employees')} > ${concatNames(employee.firstName, employee.lastName)}`);
        this.setState({
            customerId: employee.customerId,
        });
    };

    render() {
        return (
            <Grid container
                  spacing={5} style={{ paddingBottom: 40 }}>
                <Grid item xs={12}>
                    <EmployeeDetails employeeId={this.props.match.params.employeeId} onEmployeeChange={this.handleEmployeeChange} />
                </Grid>
            </Grid>
        );
    }

}));
