// @flow
import moment from 'moment-timezone';
import TimeZone from 'src/constants/TimeZone';

export default function formatDateTimeString(value?: ?string | ?Date, timeZone?: $Values<typeof TimeZone>): string { // TODO: Return maybe type
    if (value === undefined || value === null) {
        return (value: any);
    }
    if (!timeZone) {
        const m = moment(value);
        return `${m.format('L')} ${m.format('LT')}`;
    }
    const m = moment.tz(value, timeZone);
    return `${m.format('L')} ${m.format('LT')}`;
}
