/* eslint-disable no-template-curly-in-string */
export default {
    'ACCEPT': 'ACEPTAR',
    'Accept': 'Aceptar',
    'ADD CATEGORY': 'AGREGAR CATEGORÍA',
    'ADD MENU ITEM': 'AGREGAR ARTÍCULO AL MEN',
    'ADD MODIFIER GROUP': 'AGREGAR MODIFICADORES DE GRUPO',
    'ADD MODIFIER': 'AGREGAR MODIFICADOR',
    'ADD NEW': 'AGREGAR NUEVO',
    'All Orders': 'Todos los pedidos',
    'April': 'Abril',
    'August': 'Agosto',
    'Cancel': 'Cancelar',
    'Change': 'Cambiar',
    'Changing': 'Cambiando',
    'CHECKS IN': 'CUENTAS',
    'Checks': 'Cuentas',
    'Contains': 'Contiene',
    'Country': 'País',
    'Create': 'Crear',
    'Creating': 'Creando',
    'December': 'Diciembre',
    'Description': 'Descripción',
    'February': 'Febrero',
    'Host': 'Anfitrión',
    'Image URL': 'Imagen URL',
    'Image': 'Imagen',
    'January': 'Enero',
    'July': 'Julio',
    'June': 'Junio',
    'Location Latitude': 'Latitud de la ubicación',
    'Location Longitude': 'Longitud de la ubicación',
    'March': 'Marzo',
    'May': 'Mayo',
    'MODIFIER GROUP': 'MODIFICADORES DE GRUPO',
    'MODIFIERS': 'MODIFICADORES',
    'Name': 'Nombre',
    'NO CHECKS': 'SIN CUENTAS',
    'NO ONGOING ORDERS': 'NO HAY PEDIDOS PENDIENTES',
    'NO ORDERS': 'NO HAY PEDIDOS',
    'NO WAITER CALLS': 'SIN LLAMADAS A MESEROS',
    'NOTE': 'NOTA',
    'November': 'Noviembre',
    'October': 'Octubre',
    'Ongoing Orders': 'Pedidos Pendientes',
    'Ordered By': 'Ordenado Por',
    'ORDERS IN': 'PEDIDOS EN FILA ',
    'Phone Number': 'Número Telefónico',
    'Port': 'Puerto',
    'POS PRINTER ROUTES': 'RUTAS DE IMPRESORA DE PUNTO DE VENTA',
    'POS Printer': 'Impresora PUNTO DE VENTA',
    'POS PRINTERS': 'IMPRESORAS DE PUNTO DE VENTA',
    'Price': 'Precio',
    'REFRESH': 'ACTUALIZAR',
    'REFRESHING': 'ACTUALIZANDO',
    'REJECT': 'RECHAZAR',
    'Reject': 'Rechazar',
    'REMOVE': 'QUITAR',
    'REMOVING': 'QUITANDO',
    'Required Max': 'Max Requerido',
    'Required Min': 'Min Requerido',
    'RESET': 'RESETEAR',
    'SAVE': 'GUARDAR',
    'SAVING': 'GUARDANDO',
    'September': 'Septiembre',
    'Service Type': 'Tipo de Servicio',
    'Settings': 'Ajustes',
    'Sign in': 'Iniciar sesión',
    'Sign out': 'Cerrar sesión',
    'Signing In': 'Iniciando sesión',
    'Size': 'Tamaño',
    'Street': 'Calle',
    'TABLE': 'MESA',
    'Table': 'Mesa',
    'The Menu': 'El Menú',
    'The Restaurant': 'El Restaurante',
    'Time Zone': 'Zona Horaria',
    'Type': 'Tipo',
    'WAITER CALLS IN': 'Llamadas a meseros pendientes',
    'Waiter Calls': 'Llamadas a meseros',
    'Welcome to Let\'s Eat Company!': 'Bienvenidos al Administrador de Let\'s Eat',
    'Welcome': 'Bienvenido',

    'Take Away': 'Para Llevar',
    'Eat Here': 'Comer aquí',
    'Sent': 'Enviado',
    'Received': 'Recibido',
    'Cancelled': 'Cancelado',
    'Rejected': 'Rechazado',
    'Accepted': 'Aceptado',
    'Served': 'Servido',
    'Picked Up': 'Recogido',
    'Cash': 'Efectivo',
    'Credit Card': 'Tarjeta',

    'Admin': 'Administración',
    'Monitoring': 'Supervisión',
    'NO RESTAURANTS TO CONTACT': 'NO HAY RESTAURANTES PARA CONTACTAR',
    'RESTAURANT': 'RESTAURANTE',
    'NOT ACCEPTED': 'NO ACEPTADO',
    'TIME SINCE NO ACTION': 'TIEMPO DESDE QUE NO HAY ACCIÓN',

    'Sign in to your account': ' ',
    'Sign In': 'Iniciar sesión',
    'Username': 'Usuario',
    'Password': 'Contraseña',
    'Incorrect username or password.': 'Nombre de usuario o contraseña incorrecta',
    'Password attempts exceeded': 'Demasiados inicios de sesión fallidos',
    'Username cannot be empty': 'El nombre de usuario no puede estar vacío',
    'User does not exist.': 'El usuario no existe',

    'Tomorrow': 'Mañana',
    'Yesterday': 'Ayer',

    'Card has insufficient funds': 'La tarjeta no cuenta con fondos suficientes',
    'Card payment not accepted': 'El pago con tarjeta no fue aceptado',

    'Sold Out': 'Agotado',
    'Hidden': 'Oculto',

    'Preparation Time (in minutes)': 'Tiempo de preparación (En minutos)',
    'Default is 15 minutes': '15 minutos por defecto',

    'Are you sure you want to reject this order? Please note that the customer has been notified that the order has been accepted already.': '¿Está seguro de que desea rechazar el pedido? Recuerde que el cliente ya ha sido notificado sobre la aceptación de esta orden.',

    'OPENING HOURS': 'HORAS DE APERTURA',
    'Opening': 'Apertura',
    'Closing': 'Cierre',
    'Monday': 'Lunes',
    'Tuesday': 'Martes',
    'Wednesday': 'Miércoles',
    'Thursday': 'Jueves',
    'Friday': 'Viernes',
    'Saturday': 'Sábado',
    'Sunday': 'Domingo',

    'Pay in App': 'Pago en App',
    'Paid in App': 'Pagado en App',
    'Pay in Store': 'Pago en Establecimiento',

    'Select cause of the rejection': 'Seleccione la causa de rechazo',
    'Closing soon': 'Cierra pronto',
    'Problem in the restaurant': 'Problema en el restaurante',
    'Sold out': 'Artículo agotado',
    'The price is incorrect': 'El precio es incorrecto',
    'Other': 'Otro',

    'New Order': 'Pedido nuevo',
    'Order Cancelled': 'Pedido cancelado',
    'Order Rejected': 'Pedido rechazado',
    'Order Accepted': 'Pedido aceptado',
    'Order Completed': 'Pedido completado',
    'Browser Refreshed': 'Buscador actualizado',
    'New Waiter Call': 'Nueva llamada a mesero',
    'Waiter Call Completed': 'Llamada a mesero completada',
    'Waiter Call Refreshed': 'Llamada a mesero actualizada',

    'From': 'Desde',
    'To': 'Hasta',
    'App': 'App',
    'Store': 'Tienda',
    'Total': 'Total',

    'Search Text': 'Texto de búsqueda',
    'Search': 'Buscar',
    'Searching': 'Buscando ',

    'Order Statistics': 'Estadísticas de pedidos',
    'Restaurants': 'Restaurantes',
    'Status': 'Estado',

    'Change Menu': 'Modificar menú',
    'Change Category': 'Modificar categoría',
    'Change Menu Item': 'Modificar artículo del menú',
    'Create Menu': 'Crear menú',
    'Create Category': 'Crear categoría',
    'Create Menu Item': 'Crear artículo del menú',
    'Are you sure you want to remove menu?': '¿Está seguro de que deseas eliminar el menú?',
    'Are you sure you want to remove category?': '¿Está seguro de que desea elminar la categoría?',
    'Are you sure you want to remove menu item?': '¿Está seguro de que desea eliminar el artículo del menú?',
    'Saved changes to menu': 'Los cambios fueron realizados en el menú',
    'Saved changes to category': 'Los cambios fueron realizados en la categoría',
    'Saved changes to menu item': 'Los cambios fueron realizados en el artículo del menú',
    'Created menu': 'Menú creado con éxito',
    'Created category': 'Categoría creada con éxito',
    'Created menu item': 'Artículo del menú creado',
    'Removed menu': 'El menú ha sido eliminado',
    'Removed category': 'La categoría ha sido eliminada',
    'Removed menu item': 'El artículo ha sido eliminado del menú',

    'Done': 'Su modificación ha sido realizada con éxito',

    'Register Restaurant': 'Registrar restaurante',
    'Register': 'Registrar',
    'Registering': 'Registro en proceso',
    'Restaurant Name': 'Nombre de restaurante',
    'Restaurant with username ${username} already exists': 'El usuario de restaurante ${username} ya existe',

    'Yes': 'Si',
    'No': 'No',

    'Live': 'En línea ',
    'Coming Soon': 'Próximamente',
    'Inactive': 'Inactivo',
    'Temporarily Offline': 'Temporalmente sin servicio',
    'Offline': 'Sin servicio',

    'Saved changes': 'Cambios guardados',
    'Mexico': 'México',
    'Sweden': 'Suecia',

    'Details': 'Detalles',

    'Restaurant': 'Restaurante',
    'Zone': 'Zone',
    'Contact': 'Contact',
    'Contact Number': 'Contact number',
    'Establishments Phone Number': 'Establishments Phone number',
    'Fiscal Address': 'Fiscal Address',
    'Address of Establishment': 'Address of Establishment',
    'Email': 'Email',
    'Bank': 'Bank',
    'Account': 'Account',
    'Account Number': 'Account number',
    'Tax ID': 'Tax ID',
    'Business Name': 'Business name',
    'Contact Name': 'Contact name',
    'Email Invoices': 'Email Invoices',
    'Payment in Establishment': 'Payment in establishment',
    'Percentage Agreed': 'Percentage Agreed',
    'Device': 'Device',
    'Responsible': 'Responsible',
    'Bill': 'Bill',

    'Sign in Details': 'Detalles del inicio de sesión',
    'Other Details': 'Otros detalles',
    'Open': 'Abrir',

    'Self Service': 'Auto Servicio ',
    'Table Service': 'Servicio a la mesa',

    'Copy Menu from other Restaurant': 'Copiar menú de otro restaurante',
    'Restaurant Id': 'Identificación del restaurante',
    'Copy': 'Copiar',
    'Copying': 'Copiando',
    'Copied Restaurant Menu': 'Menú de restaurante copiado',

    'Promo Enabled': 'Promoción Activada',
    'Promo Image URL': 'URL de Imagen de promoción',
    'Promo Text': 'Texto de promoción',
    'Promo Price': 'Precio de promoción',

    'Customers': 'Customers',
    'Customer': 'Customer',
    'Banners': 'Banners',
    'Banner': 'Banner',

    'Connection lost': 'Conexión perdida',
    'Reconnecting': 'Reconectando',
    'Failed to sign in': 'Error al iniciar sesión',
    'Retrying': 'Reintentando',
    'No connection': 'Sin conexión',

    'Move Left': 'Desplaza izquierda',
    'Move Right': 'Desplaza derecha',
    'Move Up': 'Desplaza arriba',
    'Move Down': 'Desplaza abajo',
    'Duplicate': 'Duplicar',
    'Remove': 'Remover',

    'Mo': 'Lu',
    'Tu': 'Ma',
    'We': 'Mi',
    'Th': 'Ju',
    'Fr': 'Vi',
    'Sa': 'Sa',
    'Su': 'Do',

    'What were the reasons?': '¿Cuáles fueron los motivos?',
    'The food was cold': 'La comida estaba fría',
    'Missing or incorrect items': 'Elementos faltantes o incorrectos',
    'The food was not prepared': 'La comida no estaba preparada',
    'Confused pick-up place': 'Lugar de recogido confuso',
    'Bad customer service': 'Mala atención a cliente',
    'What do you enjoy most about Let’s Eat?': '¿Qué es lo que más disfrutas de Let’s Eat?',
    'Punctuality': 'Puntualidad',
    'The application': 'Facilidad de la aplicación',
    'The food': 'Sabor de la comida',
    'Presentation': 'Presentación',
    'Good customer service': 'Buena atención a cliente',

    '@avarage average based on @reviews reviews (@dismissed of @total dismissed)': 'Promedio de @avarage basado en @reviews comentarios (@dismissed de @total sin respuesta)',
    '1 heart': '1 corazón',
    '2 hearts': '2 corazones',
    '3 hearts': '3 corazones',
    '4 hearts': '4 corazones',
    '5 hearts': '5 corazones',
    'Answer (Good Experience)': 'Respuesta (Buena Experiencia)',
    'Answer (Bad Experience)': 'Respuesta (Mala Experiencia)',
    'Answers': 'Respuestas',
    'Other Answers (Good Experience)': 'Otras respuestas (Buena Experiencia)',
    'Other Answers (Bad Experience)': 'Otras respuestas (Mala Experiencia)',

    'Add Credits': 'Agregar Créditos',
    'Download CSV': 'Descargar CSV',
    'Refresh': 'Actualizar',
    'Send Notification': 'Enviar notificación',
    'Mark as Solved': 'Marcar como resuelto',
    '24/7': '24/7',
    'Benefit Plan Details': 'Detalles de plan de beneficios',
    'Active': 'Activo',
    'Paused': 'Pausado',
    'Credits': 'Créditos',
    'Amount of credits that the employee can order with': 'Cantidad de créditos disponibles para ordenar del empleado',
    'Restrict Credits to Delivery': 'Limita créditos al servicio de entrega',
    'Meals': 'Comidas',
    'Max number of meals per week': 'Número máximo de comidas por semana',
    'Days': 'Días',
    'Days of week when the employee can order': 'Días de la semana en que los empleados pueden ordenar',
    'Hours': 'Horas',
    'Time of day when the employee can order': 'Horarios en que el empleado puede ordenar',
    'Benefit Plans': 'Planes de beneficios',
    'Created': 'Creado',
    'Create Benefit Plan': 'Crea un plan de beneficios',
    'Are you sure you want to remove this benefit plan?': '¿Estás seguro de eliminar este plan de beneficios?',
    'Are you sure you want to remove this employee?': '¿Estás seguro de eliminar a este empleado?',
    'Change Benefit Plan': 'Cambiar plan de beneficios',
    'Remove Benefit Plan': 'Eliminar plan de beneficios',
    'Time of day when the employee can order, e.g. 11:00-13.00': 'Horario del día en que se pueden realizar los pedidos, Ej. 11:00-13:00',
    'Connected Customers': 'Clientes conectados',
    'Mobile Number': 'Número de celular',
    'Payroll Payments Enabled': 'Pagos con nómina habilitados',
    'Company Details': 'Detalles de la compañía',
    'Address': 'Dirección',
    'Contact First Name': 'Primer nombre del contacto',
    'Contact Last Name': 'Apellido del contacto',
    'Contact Email': 'Correo electrónico del contacto',
    'Contact Phone Number': 'Número de contacto',
    'Create BenefitPlan': 'Crea un plan de beneficios',
    'Register Company': 'Registra una empresa',
    'Benefit Plan': 'Plan de beneficios',
    'Used Credits': 'Créditos utilizados',
    'Orders': 'Órdenes',
    'Total Used Credits': 'Total de créditos utilizados',
    'Ignored': 'Ignorado',
    'Number of orders': 'Número de órdenes',
    'Credits Payments': 'Pagos de créditos',
    'Paid At': 'Pagado en',
    'You must select at least one customer to connect.': 'Debes seleccionar por lo menos un empleado para conectar',
    'Unconnected Customers': 'Cliente no conectado',
    'Unknown': 'Desconocido',
    'Company Name': 'Nombre de la compañía',
    'Signed Up': 'Registrado',
    'Connect Customer': 'Conecta usuario',
    'Add Employee': 'Agrega empleado',
    'Adding': 'Agregando',
    'Add': 'Agrega',
    'First Name': 'Nombre',
    'Last Name': 'Apellido',
    'Change Employee': 'Cambiar empleado',
    'Remove Employee': 'Eliminar empleado',
    'Employee Details': 'Detalles del empleado',
    'Modified': 'Modificado',
    'Connected': 'Conectado',
    'Employees': 'Empleados',
    'Not connected': 'Desconectado',
    'Gifts': 'Regalos',
    'Removed': 'Eliminado',
    'Day': 'Día',
    'Employee': 'Empleado',
    'Company': 'Compañía',
    'Per Employee': 'Por Empleado',
    'Paid Amount': 'Monto pagado',
    'All Payments': 'Todos los pagos',
    'Credits Billing': 'Factura de subsidio',
    'Payroll Billing': 'Factura de nómina',
    'Something went wrong': 'Ha ocurrido un error',
    'This browser does not support System Notifications': 'Este navegador no soporta el sistema de notificaciones',
    'Notifications configured!': '¡Notificaciones configuradas!',
    'is required': 'es requerido',
    'is not a number': 'no es un número',
    'is not an integer': 'no es un valor permitido',
    'is not a positive number': 'no es un número positivo',
    'is not an url': 'no es un url',
    'is not an url starting with https://': 'no es un url que comienza con https://',
    'is not an id': 'no es un número de identificación',
    'is not a comma separated list of ids': 'No es una lista de número de identificación separados por coma',
    'is not valid hours syntax, see https://wiki.openstreetmap.org/wiki/Key:opening_hours': 'Formato de horario incorrecto, ver https://wiki.openstreetmap.org/wiki/Key:opening_hours',
    'is not a valid time range, must be in format 08:00-17:00': 'No es un rango de horario válido, el formato debe ser 08:00-17:00',
    'Agreement': 'Acuerdo',
    'Billing': 'Facturación',
    'The food arrived late': 'La comida llegó tarde',

};
