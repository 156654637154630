// flow
import apisauce from 'apisauce';
import { Auth } from 'aws-amplify';
import { APP_VERSION } from 'src/config/appVersion';
import { devModeConfig, devModeEnabled } from 'src/config/debugConfig';
import { SECONDS } from 'src/constants/TimeUnit';

const REQUEST_METHODS_WITH_PARAMS = ['get', 'head', 'delete', 'link', 'unlink'];

const create = (baseURL = '/') => {
    const api = apisauce.create({
        baseURL,
        headers: {
            'Content-Type': 'application/json',
        },
        timeout: 60 * SECONDS,
    });

    const isDebuggingInChrome = process.env.NODE_ENV === 'development' && !!window.navigator.userAgent; // eslint-disable-line no-undef
    if (devModeEnabled() && devModeConfig().logHttpToConsole.enabled && isDebuggingInChrome) {
        api.addRequestTransform(request => {
            const other = {};
            // if (request.headers) {
            //     other.headers = request.headers;
            // }
            if (request.params) {
                other.params = request.params;
            }
            if (request.data) {
                other.data = request.data;
            }
            if (devModeConfig().logHttpToConsole.verbose) {
                console.log(`${request.method.toUpperCase()} ${baseURL}${request.url}`, JSON.stringify(other, null, 2));
            } else {
                console.log(`${request.method.toUpperCase()} ${baseURL}${request.url}`, other);
            }
        });
        api.addMonitor((response) => {
            if (devModeConfig().logHttpToConsole.verbose) {
                console.log(`${response.status} <- ${response.config.url}`, JSON.stringify(response.data, null, 2));
            } else {
                console.log(`${response.status} <- ${response.config.url}`, response.data);
            }
        });
    }

    api.addRequestTransform(request => {
        if (REQUEST_METHODS_WITH_PARAMS.includes(request.method)) {
            request.params.appVersion = APP_VERSION;
        } else {
            request.data.appVersion = APP_VERSION;
        }
    });

    // api.addMonitor(async (response) => {
    //     if (response.status === HttpStatus.FORBIDDEN) {
    //         await Auth.signOut();
    //     }
    // });

    const withIdToken = (apiCall) => {
        return Auth.currentSession()
            .then(cognitoUserSession => {
                api.setHeaders({ authorization: cognitoUserSession.idToken.jwtToken });
                return apiCall(cognitoUserSession.idToken.payload['cognito:username']);
            });
        // Disabled since this causes the restaurants to log out when connection is disabled
        // .catch(() => {
        //     api.deleteHeader('authorization');
        //     return Auth.signOut();
        // });
    };

    return {
        config: api,
        appContext: (request) => withIdToken((username) => api.get('/app-context', { ...request, username })),
        getCompany: (request) => withIdToken(() => api.get('/company', request)),
        getCompanyConnectedCustomers: (request) => withIdToken(() => api.get('/company-connected-customers', request)),
        changeCompany: (request) => withIdToken(() => api.post('/change-company', request)),
        getBenefitPlans: (request) => withIdToken(() => api.get('/benefit-plans', request)),
        getBenefitPlan: (request) => withIdToken(() => api.get('/benefit-plan', request)),
        createBenefitPlan: (request) => withIdToken(() => api.post('/create-benefit-plan', request)),
        removeBenefitPlan: (request) => withIdToken(() => api.post('/remove-benefit-plan', request)),
        changeBenefitPlan: (request) => withIdToken(() => api.post('/change-benefit-plan', request)),
        getEmployees: (request) => withIdToken(() => api.get('/employees', request)),
        getEmployee: (request) => withIdToken(() => api.get('/employee', request)),
        addEmployee: (request) => withIdToken(() => api.post('/add-employee', request)),
        removeEmployee: (request) => withIdToken(() => api.post('/remove-employee', request)),
        changeEmployee: (request) => withIdToken(() => api.post('/change-employee', request)),
        getUnconnectedCustomers: (request) => withIdToken(() => api.get('/unconnected-customers', request)),
        connectCustomerToBenefitPlan: (request) => withIdToken(() => api.post('/connect-customer-to-benefit-plan', request)),
        getCompanyCreditsBillingData: (request) => withIdToken(() => api.get('/credits-billing-data', request)),
        getCompanyCreditsBillingDataForBenefitPlan: (request) => withIdToken(() => api.get('/credits-billing-data-for-benefit-plan', request)),
        getPayrollBillingData: (request) => withIdToken(() => api.get('/payroll-billing-data', request)),
        getCompanyOptions: (request) => withIdToken(() => api.get('/company-options', request)),
    };
};

export default {
    create,
};
