// @flow
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import RadioGroup from '@material-ui/core/RadioGroup';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { I18n } from 'aws-amplify';
import * as React from 'react';
import { Field, Form } from 'react-final-form';
import { withRouter } from 'react-router-dom';
import ChangeToolbarButton from 'src/components/mui-datatables/ChangeToolbarButton';
import RefreshToolbarButton from 'src/components/mui-datatables/RefreshToolbarButton';
import Radio from 'src/components/Radio';
import TextField from 'src/components/TextField';
import UpdatingContentProgress from 'src/components/UpdatingContentProgress';
import api from 'src/config/api';
import history from 'src/config/history';
import RoutePath from 'src/constants/RoutePath';
import ChangeEmployeeDialog, { CHANGE_EMPLOYEE } from 'src/scenes/employee/ChangeEmployeeDialog';
import type { BenefitPlanId, CompanyId, CustomerId, EmployeeId, EmployeeIdAtCompany, EmployeeNumberAtCompany } from 'src/types/Id';
import alertKnownErrorOrSomethingWentWrong from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import setUrlQueryParameter from 'src/utils/history/setUrlQueryParameter';
import RegExps from 'src/utils/RegExps';
import formatDateTimeString from 'src/utils/transforms/formatDateTimeString';
/* eslint-disable no-use-before-define */
type Props = {
    employeeId: EmployeeId,
    onEmployeeChange?: (EmployeeVm) => {},
    classes: Object,
};

type State = {
    loading: boolean,
    employeeDetails?: {|
        employeeId: EmployeeId,
        benefitPlanId: BenefitPlanId,
        email: string,
        employeeIdAtCompany?: EmployeeIdAtCompany,
        employeeNumberAtCompany?: EmployeeNumberAtCompany,
        firstName: string,
        lastName: string,
        paused: string,
        connectedAt: string,
        createdAt: string,
        modifiedAt: string,
    |},
};

type EmployeeVm = {|
    employeeId: EmployeeId,
    companyId: CompanyId,
    benefitPlanId: BenefitPlanId,
    customerId?: CustomerId,
    email: string,
    employeeIdAtCompany?: EmployeeIdAtCompany,
    employeeNumberAtCompany?: EmployeeNumberAtCompany,
    firstName: string,
    lastName: string,
    paused?: boolean,
    connectedAt?: Date,
    createdAt: Date,
    modifiedAt: Date,
|};
/* eslint-enable no-use-before-define */

const INITIAL_STATE = {
    loading: false,
};

export default withRouter(withStyles(theme => ({
    linearProgress: {
        position: 'absolute',
        width: '100%',
        bottom: 0,
    },
    root: {
        paddingLeft: 8,
        paddingRight: 8,
    },
}))(class extends React.PureComponent<Props, State> {

    state = INITIAL_STATE;

    setStateAsync(state: Object) {
        return new Promise((resolve) => {
            this.setState(state, resolve);
        });
    }

    async componentDidMount(): Promise<void> {
        await this.load();
    }

    load = async () => {
        await this.setStateAsync({ loading: true });
        const response = await api.getEmployee({ employeeId: this.props.employeeId });
        await this.setStateAsync({ loading: false });
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        const employee: EmployeeVm = response.data;

        this.props.onEmployeeChange && this.props.onEmployeeChange(employee);

        this.setState({
            employeeDetails: {
                employeeId: employee.employeeId,
                benefitPlanId: employee.benefitPlanId,
                email: employee.email,
                employeeIdAtCompany: employee.employeeIdAtCompany,
                employeeNumberAtCompany: employee.employeeNumberAtCompany,
                firstName: employee.firstName,
                lastName: employee.lastName,
                paused: employee.paused ? 'true' : 'false',
                connectedAt: formatDateTimeString(employee.connectedAt),
                createdAt: formatDateTimeString(employee.createdAt),
                modifiedAt: formatDateTimeString(employee.modifiedAt),
            },
        });
    };

    handleClickEdit = () => {
        history.push({
            search: setUrlQueryParameter(CHANGE_EMPLOYEE, this.props.employeeId),
        });
    };

    handleEmployeeChanged = async (deleted) => {
        if (deleted) {
            if (!this.state.employeeDetails) {
                return;
            }
            history.replace({
                pathname: RoutePath.BENEFIT_PLAN.replace(`:companyId(${RegExps.uuid})`, this.state.employeeDetails.benefitPlanId),
                search: history.location.search,
            });
            return;
        }
        await this.load();
    };

    render() {
        const { classes } = this.props;
        return (
            <div style={{ position: 'relative' }}>
                <ChangeEmployeeDialog onEmployeeChanged={this.handleEmployeeChanged} />
                <UpdatingContentProgress loading={this.state.loading} />
                <Card classes={{ root: classes.root }}>
                    <CardHeader
                        title={<Typography display="block" gutterBottom variant="h6">{I18n.get('Employee Details')}</Typography>}
                        action={
                            <div>
                                <RefreshToolbarButton onClick={this.load} />
                                <ChangeToolbarButton onClick={this.handleClickEdit} />
                            </div>
                        }
                    />
                    <CardContent>
                        {this.renderContent()}
                    </CardContent>
                </Card>
            </div>
        );
    }

    onSubmit = form => {
    };

    renderContent() {
        return (<>
            <Form
                onSubmit={this.onSubmit}
                initialValues={{
                    employee: this.state.employeeDetails || {},
                }}
                render={({ handleSubmit, submitting, pristine, values }) => (
                    <Grid container spacing={3}>

                        <Grid item xs={12} sm={6}>
                            <Field name="employee.firstName"
                                   component={TextField}
                                   label={I18n.get('First Name')}
                                   fullWidth
                                   disabled
                            />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <Field name="employee.lastName"
                                   component={TextField}
                                   label={I18n.get('Last Name')}
                                   fullWidth
                                   disabled
                            />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <Field name="employee.email"
                                   component={TextField}
                                   label={I18n.get('Email')}
                                   fullWidth
                                   disabled
                            />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <Field name="employee.employeeIdAtCompany"
                                   component={TextField}
                                   label={I18n.get('Employee Id')}
                                   fullWidth
                                   disabled
                            />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <Field name="employee.employeeNumberAtCompany"
                                   component={TextField}
                                   label={I18n.get('Employee Number')}
                                   fullWidth
                                   disabled
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <RadioGroup row>
                                <FormControlLabel
                                    label={I18n.get('Active')}
                                    control={
                                        <Field
                                            name="employee.paused"
                                            component={Radio}
                                            type="radio"
                                            color="primary"
                                            value="false"
                                            disabled
                                        />
                                    }
                                />
                                <FormControlLabel
                                    label={I18n.get('Paused')}
                                    control={
                                        <Field
                                            name="employee.paused"
                                            component={Radio}
                                            type="radio"
                                            color="primary"
                                            value="true"
                                            disabled
                                        />
                                    }
                                />
                            </RadioGroup>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <Field name="employee.createdAt"
                                   component={TextField}
                                   label={I18n.get('Created')}
                                   fullWidth
                                   disabled
                            />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <Field name="employee.modifiedAt"
                                   component={TextField}
                                   label={I18n.get('Modified')}
                                   fullWidth
                                   disabled
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Field name="employee.connectedAt"
                                   component={TextField}
                                   label={I18n.get('Connected')}
                                   placeholder={I18n.get('No')}
                                   InputLabelProps={{ shrink: true }}
                                   fullWidth
                                   disabled
                            />
                        </Grid>

                    </Grid>
                )}
            />
        </>);
    }

}));
