// @flow
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { I18n } from 'aws-amplify';
import * as React from 'react';
import { Field, Form } from 'react-final-form';
import { withRouter } from 'react-router-dom';
import Spacing from 'src/components/spacing/Spacing';
import TextField from 'src/components/TextField';
import UpdatingContentProgress from 'src/components/UpdatingContentProgress';
import api from 'src/config/api';
import history from 'src/config/history';
import appLayout from 'src/theme/AppLayout';
import type { BenefitPlanId, EmployeeIdAtCompany, EmployeeNumberAtCompany } from 'src/types/Id';
import alertKnownErrorOrSomethingWentWrong from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import deleteUrlQueryParameter from 'src/utils/history/deleteUrlQueryParameter';
import hasUrlQueryParameter from 'src/utils/history/hasUrlQueryParameter';
import { required } from 'src/utils/Validator';

/* eslint-disable no-use-before-define */
type Props = {
    classes: Object,
    location: { pathname: string },
    benefitPlanId: BenefitPlanId,
    onEmployeeAdded: Function,
};

type State = {
    open: boolean,
    submitting: boolean,
    employee: {|
        firstName?: string,
        lastName?: string,
        email?: string,
        employeeIdAtCompany?: string,
        employeeNumberAtCompany?: string,
    |},
    errorMessage?: string,
};

type AddEmployeeRequest = {|
    benefitPlanId: BenefitPlanId,
    email: string,
    employeeIdAtCompany?: EmployeeIdAtCompany,
    employeeNumberAtCompany?: EmployeeNumberAtCompany,
    firstName: string,
    lastName: string,
    paused?: ?boolean,
|};
/* eslint-enable no-use-before-define */

export const ADD_EMPLOYEE = 'add-employee';
const INITIAL_STATE = {
    open: false,
    employee: {
        firstName: '',
        lastName: '',
        email: '',
        employeeIdAtCompany: '',
        employeeNumberAtCompany: '',
    },
    submitting: false,
};

export default withRouter(withStyles(theme => ({}))(class AddEmployeeDialog extends React.PureComponent<Props, State> {

    state = INITIAL_STATE;

    setStateAsync(state: Object) {
        return new Promise((resolve) => {
            this.setState(state, resolve);
        });
    }

    async componentDidMount(): Promise<void> {
        const addEmployee: boolean = hasUrlQueryParameter(ADD_EMPLOYEE, this.props.location);
        if (addEmployee) {
            await this.openDialog();
        }
    }

    async componentDidUpdate(prevProps: Props) {
        const prevAddEmployee: boolean = hasUrlQueryParameter(ADD_EMPLOYEE, prevProps.location);
        const addEmployee: boolean = hasUrlQueryParameter(ADD_EMPLOYEE, this.props.location);
        if (addEmployee && !prevAddEmployee && !this.state.open) {
            await this.openDialog();
        } else if (!addEmployee && prevAddEmployee && this.state.open) {
            this.closeDialog();
        }
    }

    componentWillUnmount() {

    }

    handleClose = () => {
        if (this.state.submitting) {
            return;
        }
        if (history.length <= 2) {
            history.replace({
                path: this.props.location.pathname,
                search: deleteUrlQueryParameter(ADD_EMPLOYEE),
            });
            return;
        }
        history.goBack();
    };

    handleExited = () => {
        this.setState(INITIAL_STATE);
    };

    openDialog = () => {
        this.setState({ open: true });
    };

    closeDialog = () => {
        this.setState({ open: false });
    };

    onSubmit = async form => {
        await this.setStateAsync({ submitting: true });
        const request: AddEmployeeRequest = {
            benefitPlanId: this.props.benefitPlanId,
            firstName: form.employee.firstName,
            lastName: form.employee.lastName,
            email: form.employee.email,
            employeeIdAtCompany: form.employee.employeeIdAtCompany,
            employeeNumberAtCompany: form.employee.employeeNumberAtCompany,
        };
        const response = await api.addEmployee(request);
        await this.setStateAsync({ submitting: false });
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        this.props.onEmployeeAdded();
        this.handleClose();
    };

    render() {
        return (
            <Dialog aria-labelledby="add-employee-dialog-title"
                    fullWidth
                    scroll="body"
                    open={this.state.open}
                    onClose={this.handleClose}
                    onExited={this.handleExited}>
                <Form
                    onSubmit={this.onSubmit}
                    initialValues={{
                        employee: this.state.employee,
                    }}
                    render={({ handleSubmit, submitting, pristine, values }) => {
                        return (

                            <form onSubmit={handleSubmit} noValidate style={appLayout.dialog.form}>
                                <DialogTitle id="add-employee-dialog-title">{I18n.get('Add Employee').toUpperCase()}</DialogTitle>
                                <DialogContent>
                                    {this.renderDialogContent(values)}
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={this.handleClose}
                                            disabled={this.state.submitting}>
                                        {I18n.get('Cancel').toUpperCase()}
                                    </Button>
                                    <Button color="primary"
                                            type="submit"
                                            disabled={this.state.submitting}>
                                        {this.state.submitting ? I18n.get('Adding').toUpperCase() : I18n.get('Add').toUpperCase()}
                                    </Button>
                                </DialogActions>
                                <UpdatingContentProgress loading={this.state.submitting} top />
                            </form>
                        );
                    }}
                />
            </Dialog>
        );
    }

    renderDialogContent(values: any) {
        return (<>
            <Grid container spacing={3}>
                {/*<Grid item xs={12}>*/}
                {/*    <Field name="employee.firstName"*/}
                {/*           label={I18n.get('First Name')}*/}
                {/*           component={TextField}*/}
                {/*           fullWidth*/}
                {/*           disabled={this.state.submitting}*/}
                {/*           required*/}
                {/*           validate={required}*/}
                {/*           autoComplete='none'*/}
                {/*    />*/}
                {/*</Grid>*/}
                {/*<Grid item xs={12}>*/}
                {/*    <Field name="employee.lastName"*/}
                {/*           label={I18n.get('Last Name')}*/}
                {/*           component={TextField}*/}
                {/*           fullWidth*/}
                {/*           disabled={this.state.submitting}*/}
                {/*           required*/}
                {/*           validate={required}*/}
                {/*           autoComplete='none'*/}
                {/*    />*/}
                {/*</Grid>*/}
                <Grid item xs={12}>
                    <Field name="employee.email"
                           label={I18n.get('Email')}
                           component={TextField}
                           fullWidth
                           disabled={this.state.submitting}
                           required
                           validate={required}
                           autoComplete='none'
                    />
                </Grid>
                <Grid item xs={12}>
                    <Field name="employee.employeeIdAtCompany"
                           label={I18n.get('Employee Id')}
                           component={TextField}
                           fullWidth
                           disabled={this.state.submitting}
                           autoComplete='none'
                    />
                </Grid>
                <Grid item xs={12}>
                    <Field name="employee.employeeNumberAtCompany"
                           label={I18n.get('Employee Number')}
                           component={TextField}
                           fullWidth
                           disabled={this.state.submitting}
                           autoComplete='none'
                    />
                </Grid>
            </Grid>

            <Grid container justify="space-around">
                <Grid item>
                    <Spacing units={8} />
                    {this.state.errorMessage &&
                    <Typography display="block" color="error">{this.state.errorMessage}</Typography>
                    }
                </Grid>
            </Grid>
        </>);
    }

}));
