// @flow
import { withStyles } from '@material-ui/core/styles';
import { I18n } from 'aws-amplify';
import MUIDataTable from 'mui-datatables';
import * as React from 'react';
import ConnectToolbarButton from 'src/components/mui-datatables/ConnectToolbarButton';
import RefreshToolbarButton from 'src/components/mui-datatables/RefreshToolbarButton';
import UpdatingContentProgress from 'src/components/UpdatingContentProgress';
import api from 'src/config/api';
import type { BenefitPlanId, CustomerId } from 'src/types/Id';
import alertKnownErrorOrSomethingWentWrong from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import getSelectedRowsData from 'src/utils/mui-datatables/getSelectedRowsData';
import concatNames from 'src/utils/transforms/concatNames';
import formatDateTimeString from 'src/utils/transforms/formatDateTimeString';

/* eslint-disable no-use-before-define */
type Props = {|
    benefitPlanId?: BenefitPlanId,
    classes: Object,
    onCustomersConnected: Function,
|};

type State = {
    benefitPlanId?: BenefitPlanId,
    loading: boolean,
    ...UnconnectedCustomersVm,
};

type UnconnectedCustomersVm = {|
    unconnectedCustomers: Array<CustomerVm>,
|};

type CustomerVm = {|
    customerId: CustomerId,
    mobileNumber: string,
    email: string,
    firstName: string,
    lastName: string,
    companyName: string,
    signedUpAt: Date,
|};
/* eslint-enable no-use-before-define */

export default withStyles(theme => ({
    table: {
        whiteSpace: 'nowrap',
        '& tr': { cursor: 'pointer' },
        '& tr:hover': { backgroundColor: 'rgba(0, 0, 0, 0.07) !important' },
    },
    toolbar: {
        paddingRight: theme.spacing(3),
    },
}))(class UnconnectedCustomers extends React.Component<Props, State> {

    state = {
        loading: true,
        unconnectedCustomers: [],
    };

    static getDerivedStateFromProps(props: Props, state: State) {
        if (props.benefitPlanId !== state.benefitPlanId) {
            return {
                benefitPlanId: props.benefitPlanId,
            };
        }
        return null;
    }

    async componentDidMount() {
        await this.load();
    }

    async componentDidUpdate(prevProps, prevState) {
        if (this.state.benefitPlanId !== prevState.benefitPlanId) {
            await this.load();
        }
    }

    setStateAsync(state: Object) {
        return new Promise((resolve) => {
            this.setState(state, resolve);
        });
    }

    load = async () => {
        if (!this.state.benefitPlanId) {
            return;
        }
        await this.setStateAsync({ loading: true });
        const response = await api.getUnconnectedCustomers({ benefitPlanId: this.state.benefitPlanId || '' });
        await this.setStateAsync({ loading: false });
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        this.setState({ unconnectedCustomers: response.data.unconnectedCustomers });
    };

    handleClickConnect = () => {
        alert(I18n.get('You must select at least one customer to connect.'));
    };

    connectMultiple = async (customerIds: Array<CustomerId>) => {
        await this.setStateAsync({ loading: true });
        for (let customerId of customerIds) {
            const response = await api.connectCustomerToBenefitPlan({ customerId, benefitPlanId: this.state.benefitPlanId || '' });
            if (!response.ok) {
                await this.setStateAsync({ submitting: false });
                await this.load();
                alertKnownErrorOrSomethingWentWrong(response);
                return;
            }
        }
        await this.load();
        this.props.onCustomersConnected();
    };

    render() {
        const { classes } = this.props;
        return (
            <div style={{ position: 'relative' }}>
                <UpdatingContentProgress loading={this.state.loading} />
                <MUIDataTable
                    className={classes.table}
                    title={I18n.get('Unconnected Customers')}
                    data={this.state.unconnectedCustomers.map((customer) => ({
                        customerId: customer.customerId,
                        name: concatNames(customer.firstName, customer.lastName),
                        email: customer.email || '',
                        companyName: customer.companyName || '',
                        signedUpAt: formatDateTimeString(customer.signedUpAt) || I18n.get('Unknown'),
                    }))}
                    columns={[
                        {
                            name: 'customerId',
                            label: ' ',
                            options: {
                                display: 'excluded',
                                filter: false,
                            },
                        },
                        {
                            name: 'name',
                            label: I18n.get('Name'),
                            options: {
                                filter: false,
                            },
                        },
                        {
                            name: 'email',
                            label: I18n.get('Email'),
                            options: {
                                filter: false,
                            },
                        },
                        {
                            name: 'companyName',
                            label: I18n.get('Company Name'),
                            options: {
                                filter: false,
                            },
                        },
                        {
                            name: 'signedUpAt',
                            label: I18n.get('Signed Up'),
                            options: {
                                filter: false,
                                searchable: false,
                            },
                        },
                    ]}
                    options={{
                        responsive: 'scrollMaxHeight',
                        selectableRows: 'multiple',
                        filterType: 'multiselect',
                        rowsPerPage: 100,
                        customToolbar: () => {
                            return (
                                <>
                                    <RefreshToolbarButton onClick={this.load} />
                                    <ConnectToolbarButton tooltip={I18n.get('Connect Customer')} onClick={this.handleClickConnect} />
                                </>
                            );
                        },
                        customToolbarSelect: (selectedRows, displayData) => {
                            const selectedRowsData = getSelectedRowsData(selectedRows, displayData);
                            const selectedCustomerIds = selectedRowsData.map(d => d[0]);
                            const handleClickConnect = async () => {
                                await this.connectMultiple(selectedCustomerIds);
                            };
                            return (
                                <div className={classes.toolbar}>
                                    <ConnectToolbarButton tooltip={I18n.get('Connect Customer')} onClick={handleClickConnect} />
                                </div>
                            );
                        },
                    }}
                />
            </div>
        );
    }

});
