// @flow
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import { I18n } from 'aws-amplify';
import moment from 'moment';
import * as React from 'react';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import AppActions from 'src/AppReducer';
import FromToFilter from 'src/components/FromToFilter';
import RoutePath from 'src/constants/RoutePath';
import CompanyCreditsBillingData from 'src/scenes/credits-billing/CompanyCreditsBillingData';
import CompanyCreditsBillingDataForBenefitPlan from 'src/scenes/credits-billing/CompanyCreditsBillingDataForBenefitPlan';
import type { BenefitPlanId, CompanyId } from 'src/types/Id';

/* eslint-disable no-use-before-define */
type Props = {|
    setAppTitle: string => void,
    companyId: CompanyId,
    classes: Object,
    match: { params: { benefitPlanId: BenefitPlanId } }
|};

type State = {|
    filter: {|
        from: Date,
        to: Date,
    |},
|};
/* eslint-enable no-use-before-define */

export default connect(state => ({
    companyId: state.app.companyId,
}), {
    setAppTitle: AppActions.setTitle,
})(withStyles(theme => ({}))(class CompanyCreditsBillingPage extends React.Component<Props, State> {

    state = {
        filter: {
            from: moment().subtract(1, 'week').startOf('isoWeek').toDate(),
            to: moment().subtract(1, 'week').endOf('isoWeek').toDate(),
        },
    };

    async componentDidMount() {
        this.props.setAppTitle(I18n.get('Credits Billing'));
    }

    handleLoadCompany = (companyName, benefitPlanName) => {
        this.props.setAppTitle(`${I18n.get('Credits Billing')} > ${companyName} - ${benefitPlanName}`);
    };

    handleChangeFilter = (filter) => {
        this.setState({ filter });
    };

    render() {
        return (
            <Grid container
                  spacing={5}>
                <Grid item xs={12}>
                    <FromToFilter filter={this.state.filter} onChangeFilter={this.handleChangeFilter} />
                </Grid>
                <Grid item xs={12}>
                    <Switch>
                        <Route path={RoutePath.CREDITS_BILLING_FOR_BENEFIT_PLAN} render={(props) =>
                            <CompanyCreditsBillingDataForBenefitPlan filter={this.state.filter} benefitPlanId={props.match.params.benefitPlanId} onLoad={this.handleLoadCompany} />
                        } />
                        <Route path={RoutePath.CREDITS_BILLING} render={() => {
                            this.props.setAppTitle(I18n.get('Credits Billing'));
                            return <CompanyCreditsBillingData filter={this.state.filter} companyId={this.props.companyId} />;
                        }
                        } />
                    </Switch>
                </Grid>
            </Grid>
        );
    }

}));
