// @flow
import TextField from '@material-ui/core/TextField';
import React from 'react';

export default (props: any) => {
    const {
        input: { name, onChange, value, ...restInput },
        meta,
        ...rest
    } = props;
    const showError = ((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) && meta.touched;

    return (
        <TextField
            {...rest}
            name={name}
            helperText={showError ? meta.error || meta.submitError : rest.helperText}
            error={showError}
            InputProps={restInput}
            onChange={onChange}
            value={value}
        />
    );
};
