// @flow
import RegExps from 'src/utils/RegExps';

const RoutePath = Object.freeze({
    HOME: '/',
    COMPANIES: '/agreement',
    COMPANY: '/agreement',
    BENEFIT_PLANS: `/benefit-plans`,
    BENEFIT_PLAN: `/benefit-plans/:benefitPlanId(${RegExps.uuid})`,
    EMPLOYEE: `/employees/:employeeId(${RegExps.uuid})`,
    CREDITS_BILLING: '/credits-billing',
    CREDITS_BILLING_FOR_BENEFIT_PLAN: `/credits-billing/:benefitPlanId(${RegExps.uuid})`,
    PAYROLL_BILLING: '/payroll-billing',
});
export default RoutePath;
