// @flow
import { withStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import { I18n } from 'aws-amplify';
import deepEqual from 'fast-deep-equal';
import moment from 'moment/moment';
import MUIDataTable from 'mui-datatables';
import * as React from 'react';
import RefreshToolbarButton from 'src/components/mui-datatables/RefreshToolbarButton';
import UpdatingContentProgress from 'src/components/UpdatingContentProgress';
import api from 'src/config/api';
import history from 'src/config/history';
import RoutePath from 'src/constants/RoutePath';
import type { BenefitPlanId, CompanyId } from 'src/types/Id';
import alertKnownErrorOrSomethingWentWrong from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import formatAsCurrency from 'src/utils/formatAsCurrency';
import RegExps from 'src/utils/RegExps';

/* eslint-disable no-use-before-define */
type Props = {|
    filter: {|
        from: Date,
        to: Date,
    |},
    companyId: CompanyId,
    classes: Object,
|};

type State = {
    loading: boolean,
    ...CompanyCreditsBillingDataVm,
};

type Request = {|
    from: string,
    to: string,
    companyId: CompanyId,
|};

type CompanyCreditsBillingDataVm = {|
    rows: Array<RowVm>,
    total: TotalVm,
|};

type RowVm = {|
    benefitPlanId: BenefitPlanId,
    benefitPlanName: string,
    companyCredits: string,
    orders: number,
    notAccepted: number,
|};

type TotalVm = {|
    companyCredits: string,
    orders: number,
    notAccepted: number,
|};
/* eslint-enable no-use-before-define */

export default withStyles(theme => ({
    table: {
        whiteSpace: 'nowrap',
        '& tr': { cursor: 'pointer' },
        '& tr:hover': { backgroundColor: 'rgba(0, 0, 0, 0.07) !important' },
    },
    toolbar: {
        paddingRight: theme.spacing(3),
    },
}))(class CompanyCreditsBillingData extends React.Component<Props, State> {

    state = {
        filter: this.props.filter,
        loading: false,
        rows: [],
        total: {
            companyCredits: '',
            orders: 0,
            notAccepted: 0,
        },
    };

    async componentDidMount() {
        await this.load();
    }

    async componentDidUpdate(prevProps, prevState) {
        if (!deepEqual(prevProps.filter, this.props.filter)) {
            await this.load();
        }
    }

    setStateAsync(state: Object) {
        return new Promise((resolve) => {
            this.setState(state, resolve);
        });
    }

    load = async () => {
        await this.setStateAsync({ loading: true });
        const request: Request = {
            from: moment(this.props.filter.from).format('YYYY-MM-DD'),
            to: moment(this.props.filter.to).format('YYYY-MM-DD'),
            companyId: this.props.companyId,
        };
        const response = await api.getCompanyCreditsBillingData(request);
        await this.setStateAsync({ loading: false });
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        this.setState({ ...response.data });
    };

    render() {
        const { classes } = this.props;
        return (
            <div style={{ position: 'relative' }}>
                <UpdatingContentProgress loading={this.state.loading} />
                <MUIDataTable
                    className={classes.table}
                    title=""
                    data={this.state.rows.map((row) => ({
                        benefitPlanId: row.benefitPlanId,
                        benefitPlanName: row.benefitPlanName,
                        companyCredits: formatAsCurrency(row.companyCredits),
                        orders: row.orders,
                    }))}
                    columns={[
                        {
                            name: 'benefitPlanId',
                            label: ' ',
                            options: {
                                display: 'excluded',
                                filter: false,
                            },
                        },
                        {
                            name: 'benefitPlanName',
                            label: I18n.get('Benefit Plan'),
                            options: {},
                        },
                        {
                            name: 'companyCredits',
                            label: I18n.get('Used Credits'),
                            options: {
                                filter: false,
                                searchable: false,
                            },
                        },
                        {
                            name: 'orders',
                            label: I18n.get('Orders'),
                            options: {
                                filter: false,
                                searchable: false,
                            },
                        },
                    ]}
                    options={{
                        responsive: 'scrollMaxHeight',
                        selectableRows: 'none',
                        onRowClick: (rowData: Array<string>, rowMeta: { dataIndex: number, rowIndex: number }) => {
                            history.push({
                                pathname: RoutePath.CREDITS_BILLING_FOR_BENEFIT_PLAN.replace(`:benefitPlanId(${RegExps.uuid})`, rowData[0]),
                                search: history.location.search,
                            });
                        },
                        filterType: 'multiselect',
                        rowsPerPage: 100,
                        customToolbar: () => {
                            return (
                                <>
                                    <RefreshToolbarButton onClick={this.load} />
                                </>
                            );
                        },
                        customFooter: (count, page, rowsPerPage, changeRowsPerPage, changePage) => (
                            <TableFooter>
                                <TableRow>
                                    <TablePagination
                                        count={count}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onChangePage={(_, page) => changePage(page)}
                                        onChangeRowsPerPage={event => changeRowsPerPage(event.target.value)}
                                        rowsPerPageOptions={[10, 15, 100]}
                                    />
                                </TableRow>
                                <TableRow>
                                    <TableCell size="small">{I18n.get('Total Used Credits')}: {formatAsCurrency(this.state.total.companyCredits)}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell size="small">{I18n.get('Number of orders')}: {this.state.total.orders}</TableCell>
                                </TableRow>
                            </TableFooter>
                        ),
                        downloadOptions: { filename: 'company billing data.csv' },
                    }}
                />
            </div>
        );
    }

});
