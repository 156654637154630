// @flow
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { I18n } from 'aws-amplify';
import { Authenticator, ConfirmSignIn, ConfirmSignUp, ForgotPassword, Greetings, SignUp, TOTPSetup, VerifyContact } from 'aws-amplify-react';
import * as React from 'react';
import { connect } from 'react-redux';
import AppActions from 'src/AppReducer';

const hint = { display: 'none' };
const sectionFooterSecondaryContent = { display: 'none' };
const LetsEatCompanyTheme = Object.assign({}, { hint, sectionFooterSecondaryContent });

const styles = theme => ({
    icon: {
        margin: theme.spacing(1),
    },
});

type Props = {
    setAppTitle: string => void,
    signingIn: boolean,
    retrying: boolean,
    classes: Object,
};

class Home extends React.Component<Props> {

    componentDidMount() {
        this.props.setAppTitle(I18n.get('Welcome'));
    }

    render() {
        const { classes } = this.props;
        return (
            <Grid container direction="column" alignItems="center">
                {!this.props.signingIn &&
                <Grid item>
                     <Typography display="block" variant="h5" gutterBottom>
                        {I18n.get("Welcome to Let's Eat Company!")}
                    </Typography>
                </Grid>
                }
                {this.props.signingIn && <>
                    <div style={{ paddingTop: 64, textAlign: 'center' }}>
                        <CircularProgress className={classes.progress} size={160} />
                         <Typography display="block" variant="h4" color="primary">
                            {I18n.get('Signing In')}
                        </Typography>
                        {this.props.retrying && <>
                             <Typography display="block" variant="body1" color="primary">
                                {I18n.get('Failed to sign in')}, {I18n.get('Retrying').toLowerCase()}.
                            </Typography>
                        </>}
                    </div>
                </>}
                {!this.props.signingIn &&
                <Authenticator
                    hide={
                        [
                            Greetings,
                            // SignIn,
                            ConfirmSignIn,
                            // RequireNewPassword,
                            SignUp,
                            ConfirmSignUp,
                            VerifyContact,
                            ForgotPassword,
                            TOTPSetup
                        ]
                    }
                    // hideDefault={true}
                    theme={LetsEatCompanyTheme}
                    // errorMessage={I18n.get}
                />
                }
            </Grid>
        );
    }
}

const mapStateToProps = state => {
    return {
        signingIn: state.app.signingIn,
        retrying: state.app.retrying,
    };
};

const mapDispatchToProps = {
    setAppTitle: AppActions.setTitle,

};

const HomeWithStyles = withStyles(styles)(Home);

const HomeConnected = connect(
    mapStateToProps,
    mapDispatchToProps,
)(HomeWithStyles);

export default HomeConnected;
