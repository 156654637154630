// @flow
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import RadioGroup from '@material-ui/core/RadioGroup';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { I18n } from 'aws-amplify';
import * as React from 'react';
import { Field, Form } from 'react-final-form';
import { withRouter } from 'react-router-dom';
import Checkbox from 'src/components/Checkbox';
import ChangeToolbarButton from 'src/components/mui-datatables/ChangeToolbarButton';
import RefreshToolbarButton from 'src/components/mui-datatables/RefreshToolbarButton';
import Radio from 'src/components/Radio';
import TextField from 'src/components/TextField';
import UpdatingContentProgress from 'src/components/UpdatingContentProgress';
import api from 'src/config/api';
import history from 'src/config/history';
import RoutePath from 'src/constants/RoutePath';
import ChangeBenefitPlanDialog, { CHANGE_BENEFIT_PLAN } from 'src/scenes/benefit-plan/ChangeBenefitPlanDialog';
import type { BenefitPlanId, CompanyId } from 'src/types/Id';
import alertKnownErrorOrSomethingWentWrong from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import translateDaysString from 'src/utils/day/translateDaysString';
import setUrlQueryParameter from 'src/utils/history/setUrlQueryParameter';
import RegExps from 'src/utils/RegExps';

/* eslint-disable no-use-before-define */
type Props = {
    benefitPlanId: BenefitPlanId,
    onBenefitPlanChange?: (BenefitPlanVm) => {},
    classes: Object,
};

type State = {
    loading: boolean,
    benefitPlanDetails?: {|
        benefitPlanId: BenefitPlanId,
        companyId: CompanyId,
        name: string,
        credits: string,
        restrictedToDelivery?: boolean,
        meals: number,
        days: string,
        daysTranslated: string,
        hours: string,
        paused: string,
    |},
};

export type BenefitPlanVm = {|
    benefitPlanId: BenefitPlanId,
    companyId: CompanyId,
    name: string,
    credits: string,
    restrictedToDelivery?: boolean,
    meals?: number,
    days: string,
    hours?: string,
    paused?: boolean,
|};
/* eslint-enable no-use-before-define */

const INITIAL_STATE = {
    loading: false,
};

function defaultMeals(benefitPlan?: ?BenefitPlanVm): number {
    if (!benefitPlan) {
        return 0;
    }
    if (!benefitPlan.meals) {
        if (!benefitPlan.days) {
            return 0;
        }
        return benefitPlan.days.split(',').length;
    }
    return benefitPlan.meals;
}

export default withRouter(withStyles(theme => ({
    linearProgress: {
        position: 'absolute',
        width: '100%',
        bottom: 0,
    },
    root: {
        paddingLeft: 8,
        paddingRight: 8,
    },
}))(class extends React.PureComponent<Props, State> {

    state = INITIAL_STATE;

    setStateAsync(state: Object) {
        return new Promise((resolve) => {
            this.setState(state, resolve);
        });
    }

    async componentDidMount(): Promise<void> {
        await this.load();
    }

    load = async () => {
        await this.setStateAsync({ loading: true });
        const response = await api.getBenefitPlan({ benefitPlanId: this.props.benefitPlanId });
        await this.setStateAsync({ loading: false });
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        const benefitPlan: BenefitPlanVm = response.data;

        if (this.state.benefitPlanDetails !== benefitPlan) {
            this.props.onBenefitPlanChange && this.props.onBenefitPlanChange(benefitPlan);
        }

        this.setState({
            benefitPlanDetails: {
                benefitPlanId: benefitPlan.benefitPlanId,
                companyId: benefitPlan.companyId,
                name: benefitPlan.name,
                credits: benefitPlan.credits,
                restrictedToDelivery: benefitPlan.restrictedToDelivery,
                meals: defaultMeals(benefitPlan),
                days: benefitPlan.days,
                daysTranslated: translateDaysString(benefitPlan.days),
                hours: benefitPlan.hours || I18n.get('24/7'),
                paused: benefitPlan.paused ? 'true' : 'false',
            },
        });
    };

    handleClickEdit = () => {
        history.push({
            search: setUrlQueryParameter(CHANGE_BENEFIT_PLAN, this.props.benefitPlanId),
        });
    };

    handleBenefitPlanChanged = async (deleted) => {
        if (deleted) {
            if (!this.state.benefitPlanDetails) {
                return;
            }
            history.replace({
                pathname: RoutePath.COMPANY.replace(`:companyId(${RegExps.uuid})`, this.state.benefitPlanDetails.companyId),
                search: history.location.search,
            });
            return;
        }
        await this.load();
    };

    render() {
        const { classes } = this.props;
        return (
            <div style={{ position: 'relative' }}>
                <ChangeBenefitPlanDialog onBenefitPlanChanged={this.handleBenefitPlanChanged} />
                <UpdatingContentProgress loading={this.state.loading} />
                <Card classes={{ root: classes.root }}>
                    <CardHeader
                        title={<Typography display="block" gutterBottom variant="h6">{I18n.get('Benefit Plan Details')}</Typography>}
                        action={
                            <div>
                                <RefreshToolbarButton onClick={this.load} />
                                <ChangeToolbarButton onClick={this.handleClickEdit} />
                            </div>
                        }
                    />
                    <CardContent>
                        {this.renderContent()}
                    </CardContent>
                </Card>
            </div>
        );
    }

    onSubmit = form => {
    };

    renderContent() {
        return (<>
            <Form
                onSubmit={this.onSubmit}
                initialValues={{
                    benefitPlan: this.state.benefitPlanDetails || { days: [] },
                }}
                render={({ handleSubmit, submitting, pristine, values }) => (
                    <Grid container spacing={3}>

                        <Grid item xs={12} sm={6}>
                            <Field name="benefitPlan.name"
                                   component={TextField}
                                   label={I18n.get('Name')}
                                   fullWidth
                                   disabled
                            />
                        </Grid>

                        <Hidden xsDown>
                            <Grid item xs="none" sm={6}>
                            </Grid>
                        </Hidden>

                        <Grid item xs={12} sm={6}>
                            <Field name="benefitPlan.credits"
                                   component={TextField}
                                   label={I18n.get('Credits')}
                                   helperText={`${I18n.get('Amount of credits that the employee can order with')}`}
                                   fullWidth
                                   disabled
                            />
                        </Grid>

                        {(this.state.benefitPlanDetails && this.state.benefitPlanDetails.restrictedToDelivery) &&
                        <Grid item xs={12} sm={6}>
                            <FormControlLabel
                                label={I18n.get('Restrict Credits to Delivery')}
                                control={
                                    <Field
                                        name="benefitPlan.restrictedToDelivery"
                                        component={Checkbox}
                                        type="checkbox"
                                        color="primary"
                                        disabled
                                    />
                                }
                            />
                        </Grid>
                        }
                        {(!this.state.benefitPlanDetails || !this.state.benefitPlanDetails.restrictedToDelivery) &&
                        <Hidden xsDown>
                            <Grid item xs="none" sm={6}>
                            </Grid>
                        </Hidden>
                        }

                        <Grid item xs={12} sm={6}>
                            <Field name="benefitPlan.meals"
                                   component={TextField}
                                   label={I18n.get('Meals')}
                                   helperText={`${I18n.get('Max number of meals per week')}`}
                                   placeholder={`${(values: any).benefitPlan.days.length}`}
                                   fullWidth
                                   disabled
                            />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <Field name="benefitPlan.daysTranslated"
                                   component={TextField}
                                   label={I18n.get('Days')}
                                   helperText={`${I18n.get('Days of week when the employee can order')}`}
                                   fullWidth
                                   disabled
                            />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <Field name="benefitPlan.hours"
                                   component={TextField}
                                   label={I18n.get('Hours')}
                                   helperText={`${I18n.get('Time of day when the employee can order')}`}
                                   fullWidth
                                   disabled
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <RadioGroup row>
                                <FormControlLabel
                                    label={I18n.get('Active')}
                                    control={
                                        <Field
                                            name="benefitPlan.paused"
                                            component={Radio}
                                            type="radio"
                                            color="primary"
                                            value="false"
                                            disabled
                                        />
                                    }
                                />
                                <FormControlLabel
                                    label={I18n.get('Paused')}
                                    control={
                                        <Field
                                            name="benefitPlan.paused"
                                            component={Radio}
                                            type="radio"
                                            color="primary"
                                            value="true"
                                            disabled
                                        />
                                    }
                                />
                            </RadioGroup>
                        </Grid>

                    </Grid>
                )}
            />
        </>);
    }

}));
