import Raven from 'raven-js';
import { APP_VERSION } from 'src/config/appVersion';
import debugConfig, { devModeConfig, devModeEnabled } from 'src/config/debugConfig';
import Environment from 'src/config/Environment';

export default class RavenService {

    static install() {
        if (devModeEnabled() && devModeConfig().disableSentry) return;

        let sentryDsn;
        switch (debugConfig.environment) {
            case Environment.LOCALHOST:
                sentryDsn = 'https://6ae7de8ea19248b781899b1479a86abf@sentry.io/201600';
                break;
            case Environment.DEV:
                sentryDsn = 'https://6ae7de8ea19248b781899b1479a86abf@sentry.io/201600';
                break;
            case Environment.TEST:
                sentryDsn = 'https://e14784edf42a44efb6108e3847db4330@sentry.io/1195657';
                break;
            case Environment.PROD:
                sentryDsn = 'https://e14784edf42a44efb6108e3847db4330@sentry.io/1195657';
                break;
            default:
                break;

        }
        Raven.config(
            sentryDsn,
            {
                version: APP_VERSION,
                autoBreadcrumbs: {
                    xhr: true,
                    console: true,
                    dom: true,
                    location: true,
                },
            },
        )
            .install();
        Raven.setTagsContext({
            environment: debugConfig.environment,
            appName: 'letseatcompany',
        });
    }

    static setUsername(username) {
        if (devModeEnabled() && devModeConfig().disableSentry) return;
        Raven.setUserContext({
            email: username + '@unknown.email',
            userID: username,
            username: username,
        });
    }

    static clearUsername() {
        if (devModeEnabled() && devModeConfig().disableSentry) return;
        Raven.setUserContext();
    }

    static setCompanyId(companyId) {
        if (devModeEnabled() && devModeConfig().disableSentry) return;
        Raven.setTagsContext({
            companyId,
        });
    }

    static logInfoBreadcrumb(message, data) {
        if (devModeEnabled() && devModeConfig().disableSentry) return;
        Raven.captureBreadcrumb({
            message,
            data,
        });
    }

    static logDebug(message, data) {
        if (devModeEnabled() && devModeConfig().disableSentry) return;
        Raven.captureMessage(message, RavenService.createOptionsFrom('debug', data));
    }

    static logInfo(message, data) {
        if (devModeEnabled() && devModeConfig().disableSentry) return;
        Raven.captureMessage(message, RavenService.createOptionsFrom('info', data));
    }

    static logWarning(message, data) {
        if (devModeEnabled() && devModeConfig().disableSentry) return;
        Raven.captureMessage(message, RavenService.createOptionsFrom('warning', data));
    }

    static logError(message, data) {
        if (devModeEnabled() && devModeConfig().disableSentry) return;
        Raven.captureMessage(message, RavenService.createOptionsFrom('error', data));
    }

    static createOptionsFrom(level, data) {
        let options = {
            level,
        };
        if (data) {
            options.extra = data;
        }
        return options;
    }
}
