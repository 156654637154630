// @flow
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { I18n } from 'aws-amplify';
import deepEqual from 'fast-deep-equal';
import moment from 'moment/moment';
import * as React from 'react';
import RefreshToolbarButton from 'src/components/mui-datatables/RefreshToolbarButton';
import UpdatingContentProgress from 'src/components/UpdatingContentProgress';
import api from 'src/config/api';
import EmployeePayrollPaymentsTable from 'src/scenes/payroll-billing/EmployeePayrollPaymentsTable';
import PayrollBillingOrdersTable from 'src/scenes/payroll-billing/PayrollPaymentsTable';
import type { CompanyId, CustomerId, EmployeeIdAtCompany, EmployeeNumberAtCompany, OrderId } from 'src/types/Id';
import alertKnownErrorOrSomethingWentWrong from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import formatAsCurrency from 'src/utils/formatAsCurrency';

/* eslint-disable no-use-before-define */
type Props = {|
    filter: {|
        from: Date,
        to: Date,
    |},
    companyId: CompanyId,
    onLoad?: string => {},
    classes: Object,
|};

type State = {
    loading: boolean,
    ...CompanyPayrollBillingDataVm,
};

type Request = {|
    from: string,
    to: string,
    companyId: CompanyId,
|};

type CompanyPayrollBillingDataVm = {|
    companyId: CompanyId,
    companyName: string,
    paidWithPayroll: string,
    customers: Array<{|
        customerId: CustomerId,
        customerName: string,
        mobileNumber: string,
        email: string,
        employeeIdAtCompany?: EmployeeIdAtCompany,
        employeeNumberAtCompany?: EmployeeNumberAtCompany,
        paidWithPayroll: string,
        orders: number,
    |}>,
    payrollPayments: Array<PayrollPaymentVm>,
|};

export type PayrollPaymentVm = {|
    orderId: OrderId,
    customerId: CustomerId,
    createdAt: Date,
    paidWithPayroll: string,
    customerName: string,
    mobileNumber: string,
    email: string,
    employeeIdAtCompany?: EmployeeIdAtCompany,
    employeeNumberAtCompany?: EmployeeNumberAtCompany,
|};
/* eslint-enable no-use-before-define */

export default withStyles(theme => ({
    root: {
        paddingLeft: 8,
        paddingRight: 8,
    },
    table: {
        whiteSpace: 'nowrap',
        '& tr': { cursor: 'pointer' },
        '& tr:hover': { backgroundColor: 'rgba(0, 0, 0, 0.07) !important' },
    },
    toolbar: {
        paddingRight: theme.spacing(3),
    },
}))(class PayrollBillingData extends React.Component<Props, State> {

    state = {
        loading: false,
        companyId: ('': any),
        companyName: '',
        customers: [],
        paidWithPayroll: '0',
        payrollPayments: [],
    };

    async componentDidMount() {
        await this.load();
    }

    async componentDidUpdate(prevProps, prevState) {
        if (!deepEqual(prevProps.filter, this.props.filter)) {
            await this.load();
        }
    }

    setStateAsync(state: Object) {
        return new Promise((resolve) => {
            this.setState(state, resolve);
        });
    }

    load = async () => {
        await this.setStateAsync({ loading: true });
        const request: Request = {
            companyId: this.props.companyId,
            from: moment(this.props.filter.from).format('YYYY-MM-DD'),
            to: moment(this.props.filter.to).format('YYYY-MM-DD'),
        };
        const response = await api.getPayrollBillingData(request);
        await this.setStateAsync({ loading: false });
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        this.setState({ ...response.data });
        this.props.onLoad && this.props.onLoad(response.data.companyName);
    };

    render() {
        const { classes } = this.props;
        return (
            <Grid container
                  spacing={5}>
                <Grid item xs={12}>
                    <UpdatingContentProgress loading={this.state.loading} />
                    <Card classes={{ root: classes.root }}>
                        <CardHeader
                            title={<Typography display="block" gutterBottom variant="h6">{I18n.get('Total')}</Typography>}
                            action={
                                <div>
                                    <RefreshToolbarButton onClick={this.load} />
                                </div>
                            }
                        />
                        <CardContent>
                            <Grid container spacing={3}>
                                <Grid item xs={6} sm={3}>
                                    <Typography variant="caption" display="block" gutterBottom color="textSecondary">{I18n.get('Paid Amount')}</Typography>
                                    <Typography variant="body1" gutterBottom>{formatAsCurrency(this.state.paidWithPayroll)}</Typography>
                                </Grid>
                                <Grid item xs={6} sm={3}>
                                    <Typography variant="caption" display="block" gutterBottom color="textSecondary">{I18n.get('Number of orders')}</Typography>
                                    <Typography variant="body1" gutterBottom>{this.state.payrollPayments.length}</Typography>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12}>
                    <EmployeePayrollPaymentsTable customers={this.state.customers} loading={this.state.loading} onClickRefresh={this.load} />
                </Grid>
                <Grid item xs={12}>
                    <PayrollBillingOrdersTable payrollPayments={this.state.payrollPayments} loading={this.state.loading} onClickRefresh={this.load} />
                </Grid>
            </Grid>
        );
    }

});
