// @flow
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import { I18n } from 'aws-amplify';
import * as React from 'react';
import { connect } from 'react-redux';
import AppActions from 'src/AppReducer';
import CompanyDetails from 'src/scenes/company/CompanyDetails';
import type { CompanyId } from 'src/types/Id';

/* eslint-disable no-use-before-define */
type Props = {|
    companyId: CompanyId,
    setAppTitle: string => void,
    classes: Object,
    match: { params: { companyId: CompanyId } }
|};
/* eslint-enable no-use-before-define */

export default connect(state => ({
    companyId: state.app.companyId,
}), {
    setAppTitle: AppActions.setTitle,
})(withStyles(theme => ({}))(class CompanyPage extends React.Component<Props> {

    handleCompanyChange = (company) => {
        this.props.setAppTitle(`${I18n.get('Company Details')}`);
    };

    render() {
        return (
            <Grid container
                  spacing={5} style={{ paddingBottom: 40 }}>
                <Grid item xs={12}>
                    <CompanyDetails companyId={this.props.companyId} onCompanyChange={this.handleCompanyChange} />
                </Grid>
            </Grid>
        );
    }

}));
