import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
    typography: {
        htmlFontSize: 10,
    },
    palette: {
        primary: {
            light: '#65fbff',
            main: '#00C8DC',
            dark: '#0097aa',
            contrastText: '#fff',
        },
        secondary: {
            light: '#80fffa',
            main: '#3affc7',
            dark: '#00cb96',
            contrastText: '#000',
        },
        background: {
            default: '#f2f2f2',
        },
    },
});

export default theme;
