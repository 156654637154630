import * as React from 'react';
import ReactDOM from 'react-dom';
import AppRoot from 'src/AppRoot';
import * as serviceWorker from 'src/serviceWorker';
import './index.css';

ReactDOM.render(<AppRoot />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register({
    onUpdate: () => {},
    onSuccess: () => {},
});
