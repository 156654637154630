// @flow
import Checkbox from '@material-ui/core/Checkbox';
import * as React from 'react';

export default (props: any) => {
    const {
        input: { name, onChange, checked, ...restInput },
        ...rest
    } = props;

    return (
        <Checkbox
            {...rest}
            name={name}
            inputProps={restInput}
            onChange={onChange}
            checked={checked}
        />
    );
};
